<template>
  <div class="mainView">
    <!-- <input ref="fileInput" type="file" @change="handleFileChange" /> -->
    <div>
      <label for="file-upload" class="custom-file-upload"> 上传题库 </label>
      <input
        id="file-upload"
        type="file"
        style="display: none"
        @change="handleFileUpload"
        v-once
        :key="fileKey"
      />
      <div class="subtext">只支持xlsx格式Excel，文件名为题库名。</div>
    </div>
    <div class="listBack">
      <div class="titleText">我的题库</div>
      <div class="cellView" v-for="(item, index) in items">
        <div>
          <div class="rowView">
            <div class="celltitle">题库名：</div>
            <div class="celltitle">{{ item.test_name }}</div>
          </div>
          <div class="rowView">
            <div class="cellsubtext">题数：</div>
            <div class="cellsubtext">{{ item.total_count }}</div>
          </div>
        </div>
        <div class="deletText" @click="deletAction(item.test_name)">删除</div>
      </div>
    </div>
  </div>
</template>

<script>
import { showToast, showLoadingToast } from "vant";
import {
  uploadExcel,
  getTestList,
  deletTestList,
} from "../../netWorking/index";
export default {
  data() {
    return {
      items: [],
      fileKey: 0,
    };
  },

  created() {
    document.title = "我的题库";
  },
  mounted() {
    this.getlist();
  },
  methods: {
    handleFileUpload(event) {
      debugger;
      const file = event.target.files[0];
      console.log("00000");
      this.fileKey++;

      // 检查文件类型是否为 Excel
      if (
        file.type === "application/vnd.ms-excel" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        // 在此处处理所选的 Excel 文件
        // console.log("选择的文件:", file);
        this.uploadFile(file);
      } else {
        console.log("请选择有效的 Excel 文件");
        showToast("请选择有效的 Excel 文件");
        this.$refs.fileInput.value = "";
      }
    },
    //调用上传接口
    uploadFile(file) {
      let that = this;
      let fileData = file;
      let formData = new FormData();
      formData.append("file", fileData);
      showLoadingToast({
        message: "导入中...",
        forbidClick: true,
      });
      uploadExcel(formData, 0, this.uploadstate)
        .then((data) => {
          if (typeof data == "number") {
            console.log("number0");
          } else {
            console.log("上传成功");
            this.getlist();
          }
          that.$refs.fileInput.value = "";
        })
        .catch((err) => {
          //   this.$refs.fileInput.value = "";
        });
    },
    uploadstate(data) {
      if (data.state == "1") {
        console.log("上传中");
      } else if (data.state == "0") {
      } else if (data.state == "-1") {
        showToast("文件导入失败");
      }
    },
    getlist() {
      getTestList({
        userid: "0",
      })
        .then((data) => {
          console.log("data++++++++++++++++++++++");
          console.log(data);
          console.log(this.items);
          this.items = data["data"];
        })
        .catch(function (err) {
          showToast(err);
        });
    },
    deletAction(name) {
      let that = this;
      deletTestList({
        userid: "0",
        name: name,
      })
        .then((data) => {
          showToast(name + "已删除");
          that.getlist();
        })
        .catch(function (err) {
          showToast(err);
        });
    },
  },
};
</script>

<style>
.mainView {
  width: 100%;
  height: 100%;
  padding-top: 60px;
  background-color: rgb(240, 240, 240);
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.custom-file-upload span {
  color: #333;
  display: flex;
}
.listBack {
  /* width: 100%; */
  margin-top: 30px;
  flex: 1;
  padding: 30px;
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
}
.titleText {
  font-size: 24px;
  /* color: rgb(62, 88, 238); */
  text-align: left;
}
.cellView {
  /* width: 100%; */
  margin-top: 10px;
  padding: 10px 20px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: white;
}
.deletText {
  color: red;
  width: 80px;
  height: 30px;
  border: 1px solid rgba(224, 0, 0, 1);
  border-radius: 5px;
  line-height: 30px;
}
.columnView {
  display: flex;
  flex-direction: column;
}
.rowView {
  display: flex;
  flex-direction: row;
}

.subtext {
  color: rgb(243, 128, 82);
  margin-top: 30px;
}
.celltitle {
  color: black;
}
.cellsubtext {
  color: rgb(80, 76, 76);
}
</style>
