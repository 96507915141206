<template>
  <div v-if="isIOS == 0">
    <a class="cell" :href="install_url" target="_self" @click="installAction">
      <SvgWords class="cellText" title="安装百姓通" click></SvgWords>
    </a>
    <div>
      <br />
      <br />
      {{ appData.buildVersion }}【{{ appData.buildVersionNo }}】
    </div>
    <br />
    <div>更新内容：{{ appData.buildUpdateDescription }}</div>
    <br />
    <div>发布时间：{{ appData.appExpiredDate }}</div>
  </div>
  <div class="image-container" v-if="isIOS > 0">
    <img class="hover-right" v-if="isIOS == 1" src="../assets/arrow.png" />
    <div class="hover-text">{{ msg }}</div>
  </div>
</template>

<script>
import SvgWords from "@/components/view/svgword.vue";

import { getAPPInfo } from "../netWorking/index";
// 判断是否在iOS设备上的Safari浏览器中
function isSafariOnIOS() {
  const userAgent = navigator.userAgent;
  console.log("userAgent", userAgent);
  return /iP(ad|od|hone)/i.test(userAgent) && /Safari/i.test(userAgent);
}
export default {
  components: { SvgWords },

  data() {
    return {
      install_url: "",
      buildKey: "",
      isIOS: 0,
      msg: "请使用默认浏览器打开",
      appData: {},
    };
  },
  methods: {
    detectIOS() {
      const userAgent = navigator.userAgent;
      if (navigator.userAgent.indexOf("AppleWebKit") != -1) {
        if (navigator.userAgent.indexOf("MicroMessenger") != -1) {
          this.isIOS = 1;
        } else if (/iOS/.test(userAgent)) {
        } else {
          this.isIOS = 0;
          this.getappInfo();
        }
      } else {
        this.isIOS = 2;
        console.log("111111111111");
        this.msg = "请在iPhone上用浏览器打开";
      }
      console.log("000000000", userAgent);
      console.log("000000000", navigator.platform);
    },
    installAction() {
      this.isIOS = 3;
      console.log("33333333");
      this.msg = "下载中请回到手机桌面查看";
    },
    getappInfo() {
      let param = { buildKey: this.buildKey };
      console.log("getappInfo");
      console.log(param);
      getAPPInfo(param)
        .then((data) => {
          this.appData = data.data.data;
          console.log(this.appData);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    let geturl = window.location.href;
    let getqyinfo = geturl.split("?")[1]; //qycode=1001&qyname=%E4%BC%81%E4%B8%9A%E5%BF%99   截取到参数部分
    let getqys = new URLSearchParams("?" + getqyinfo); //将参数放在URLSearchParams函数中
    let key = getqys.get("key");
    if (!key || key.length != 32) {
      this.isIOS = 4;
      console.log("44444444");
      this.msg = "下载地址异常";
    } else {
      this.buildKey = key;
      this.install_url =
        "itms-services:/?action=download-manifest&url=https://www.pgyer.com/app/plist/" +
        key +
        "/install-api/s.plist";
      console.log("当前路由参数 params:", key);

      this.detectIOS();
    }
  },
};
</script>

<style>
.cell {
  /* margin-left: 40px;
  margin-right: 40px; */
  margin-top: 150px;
  /* height: 50px; */
  /* // 每行放几组，这里就除于几 */
  width: 100%;

  /*float: left; /* 向左浮动， */
  text-align: center;
  display: flex;
  /* flex-direction: column; */
  /* justify-items: center; */
  justify-content: center;
  /* align-items: center; */
}
.cellText {
  margin-left: 40px;
  margin-right: 40px;
}

.image-container {
  position: relative; /* 确保容器相对定位 */
  width: 100%; /* 可根据需要调整容器的宽度 */
  height: auto; /* 高度自适应 */
}
.hover-right {
  position: absolute;
  top: -110px;
  right: 20px;
  width: 100px;
  height: 200px;
  /* bottom: 0; 图片靠右下角 */
  transition: transform 0.3s ease; /* 添加过渡效果 */
}

/* .hover-right:hover {
  transform: translateX(50%); 
} */
.hover-text {
  font-size: 16;
  position: absolute;
  top: 90px;
  right: 40px;
  left: 40px;
  justify-content: center;
}
</style>
