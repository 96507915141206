<template>
  <div class="bodyView">
    <!-- <div class="headView">
      <img class="headImageView" src="../assets/headIcon.png" />
      <div class="headTitleView">iOS原生Api</div>
    </div> -->
    <!-- <div>使用API前请注册应用</div> -->
    <div class="contentView">
      <div ref="contentRef" class="leftContent">
        <div
          v-for="(item, index) in items"
          :key="item"
          class="leftContentItem"
          v-html="itemTitle(item)"
          @click="select(item, index)"
          :class="[
            selectedIndex == index ? 'selectedleftCellText' : 'leftCellText',
          ]"
        ></div>
      </div>
      <div class="itemContentView">
        <div class="titleName">
          {{ desc.title }}
          <div style="color: red" v-if="desc.version">
            ({{ desc.version }} 及更新版本可以)
          </div>
        </div>

        <h1 class="contentText">调用方式</h1>
        <div class="contentText" v-html="desc.demo" />
        <!-- {{ funcationName() }} -->
        <!-- </div> -->
        <div v-if="desc.parametersDesc" style="margin-top: 10px">
          <h1 class="contentText">参数说明</h1>
          <div class="contentText1" v-html="parameters()"></div>
          <div class="contentText1" v-html="parametersRemark()"></div>
        </div>
        <!-- <div class="contentText1" v-html="callBack()">
      </div> -->
        <!-- <div class="contentText1" v-html="demoStr()"></div> -->

        <div v-if="desc.describe" style="margin-top: 10px">
          <div class="contentText">说明:</div>
          <div class="contentText1" v-html="desc.describe"></div>
        </div>

        <div style="margin-top: 10px">
          <h1 class="contentText">测试示例</h1>
          <div class="contentText">用百姓通扫码打开</div>
          <div class="qrcode" ref="qrCodeUrl"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import QRCode from "qrcode";
import QRCode from "qrcodejs2";
import {
  apiData,
  funcationName,
  parameters,
  parametersRemark,
  callBack,
  demoStr,
} from "./apiData";
export default {
  data() {
    return {
      items: apiData,
      desc: apiData[0],
      selectedIndex: 0,
      qrcode: null,
    };
  },
  created() {
    this.$nextTick(() => {
      // 在此处执行你要执行的函数
      // 具体函数调用位置根据情况而定
      // this.readExcelFromRemoteFile(this.wordURL);
      this.creatQrCode();
    });
    document.title = "原生Api接口文档";
  },
  methods: {
    // 点击 item
    select(obj, index) {
      this.desc = obj;
      this.selectedIndex = index;
      this.creatQrCode();
    },
    itemTitle(item) {
      var str;
      var sp = "";
      var arr = item.id.toString().split(".");
      console.log(arr + "==" + arr.length);
      for (let index = 1; index < arr.length; index++) {
        sp = sp + "  &nbsp &nbsp ";
      }

      str = sp + item.id.toString() + item.title;
      return str;
    },
    runfuncationName() {
      return funcationName(parseInt(this.selectedIndex));
      //   var Str;
      //   //   ("var param = { key:value} <br/>bxt.getUserInfo(param, function(info) {<br/><br/>})");
      //   if (this.desc.parameters && this.desc.parameters !== "") {
      //     if (this.desc.rebackFuncation && this.desc.rebackFuncation !== "") {
      //       Str =
      //         "var parameters = " +
      //         this.desc.parameters +
      //         ";<br/>bxt." +
      //         this.desc.funcationName.replaceAll(":", "") +
      //         "(parameters,function(info) {<br/><br/>});";
      //     } else {
      //       Str =
      //         "var parameters = " +
      //         this.desc.parameters +
      //         ";<br/>bxt." +
      //         this.desc.funcationName.replaceAll(":", "") +
      //         "(parameters);";
      //     }
      //   } else {
      //     if (this.desc.rebackFuncation && this.desc.rebackFuncation !== "") {
      //       Str =
      //         "bxt." + this.desc.funcationName + "(function(info) {<br/><br/>});";
      //     } else {
      //       Str = "bxt." + this.desc.funcationName + "();";
      //     }
      //   }
      //   return Str;
    },
    parameters() {
      return parameters(this.selectedIndex);
      // var Str;
      // if (this.desc.parameters && this.desc.parameters !== "") {
      //   Str = "parameters = " + this.desc.parameters;
      // }
      // return Str;
    },

    parametersRemark() {
      return parametersRemark(this.selectedIndex);
      // var Str;
      // if (this.desc.parametersDesc && this.desc.parametersDesc !== "") {
      //   Str = "参数1：" + this.desc.parametersDesc;
      // }
      // // if (this.desc.parametersDesc && this.desc.parametersDesc !== "") {
      // //   Str = Str + this.desc.parametersDesc;
      // // }
      // return Str;
    },
    callBack() {
      return callBack(this.selectedIndex);
      // var Str;
      // if (this.desc.rebackFuncation && this.desc.rebackFuncation !== "") {
      //   Str = "<br/>callBack = " + this.desc.rebackFuncation;
      // }
      // return Str;
    },

    demoStr() {
      return demoStr(this.selectedIndex);
      // var Str;
      // //   ("var param = { key:value} <br/>bxt.getUserInfo(param, function(info) {<br/><br/>})");
      // if (this.desc.parameters && this.desc.parameters !== "") {
      //   if (this.desc.rebackFuncation && this.desc.rebackFuncation !== "") {
      //     Str =
      //       "<br/>bxt." +
      //       this.desc.funcationName.replaceAll(":", "") +
      //       "(parameters, backInfo)" +
      //       "{<br/>try{" +
      //       "<br/>bxt." +
      //       this.desc.funcationName.replaceAll(":", "") +
      //       "Back" +
      //       "= backInfo" +
      //       "<br/>window.webkit.messageHandlers.NativeFunction.postMessage({'selector':'" +
      //       this.desc.funcationName.replaceAll(":", "") +
      //       "','data':" +
      //       "parameters" +
      //       "})" +
      //       "<br/>}catch(e){<br/>alert(e.message)<br/>}<br/>}<br/>" +
      //       "function bxt_" +
      //       this.desc.rebackFuncation +
      //       "{<br/>bxt." +
      //       this.desc.funcationName.replaceAll(":", "") +
      //       "Back = {data:info};<br/>}";
      //   } else {
      //     Str =
      //       "<br/>bxt." +
      //       this.desc.funcationName.replaceAll(":", "") +
      //       "(parameters)" +
      //       "{<br/>try{" +
      //       "<br/>window.webkit.messageHandlers.NativeFunction.postMessage({'selector':'" +
      //       this.desc.funcationName +
      //       "','data':" +
      //       "parameters" +
      //       "})" +
      //       "<br/>}catch(e){<br/>alert(e.message)<br/>}<br/>}";
      //   }
      // } else {
      //   if (this.desc.rebackFuncation && this.desc.rebackFuncation !== "") {
      //     Str =
      //       "<br/>bxt." +
      //       this.desc.funcationName.replaceAll(":", "") +
      //       "(backInfo)" +
      //       "{<br/>try{" +
      //       "<br/>bxt." +
      //       this.desc.funcationName.replaceAll(":", "") +
      //       "Back" +
      //       "= backInfo" +
      //       "<br/>window.webkit.messageHandlers.NativeFunction.postMessage({'selector':'" +
      //       this.desc.funcationName +
      //       "'})" +
      //       "<br/>}catch(e){<br/>alert(e.message)<br/>}<br/>}<br/>" +
      //       "function bxt_" +
      //       this.desc.rebackFuncation +
      //       "{<br/>bxt." +
      //       this.desc.funcationName.replaceAll(":", "") +
      //       "Back = {data:info};<br/>}";
      //   } else {
      //     Str =
      //       "<br/>bxt." +
      //       this.desc.funcationName.replaceAll(":", "") +
      //       "()" +
      //       "{<br/>try{" +
      //       "<br/>window.webkit.messageHandlers.NativeFunction.postMessage({'selector':'" +
      //       this.desc.funcationName +
      //       "'})" +
      //       "<br/>}catch(e){<br/>alert(e.message)<br/>}<br/>}";
      //   }
      // }
      // return "<br/><br/>//" + this.desc.id + this.desc.title + Str;
    },
    creatQrCode() {
      console.log("development");
      var qrStr;
      if (process.env.NODE_ENV === "development") {
        qrStr =
          "http://10.0.220.71:8080/#/testDemo?index=" + this.selectedIndex;
        // alert("开发环境");
      } else {
        // alert("生产环境");
        qrStr = "http://150.158.176.173/#/testDemo?index=" + this.selectedIndex;
      }

      if (this.qrcode) {
        // 有新的二维码地址了，先把之前的清除掉
        // this.$refs[this.id].innerHTML = "";
        this.qrcode.clear();
        console.log("+++++++++" + qrStr);
        this.qrcode.makeCode(qrStr); // make another code.
      } else {
        console.log("---------" + qrStr);
        // this.qrcode = new QRCode(this.$refs[this.id], {
        //   text: this.text, //页面地址 ,如果页面需要参数传递请注意哈希模式#
        //   width: this.width, // 二维码宽度 （不支持100%）
        //   height: this.height, // 二维码高度 （不支持100%）
        //   colorDark: this.colorDark,
        //   colorLight: this.colorLight,
        //   correctLevel: QRCode.CorrectLevel.H,

        this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: qrStr, //"https://blog.csdn.net/weixin_42601136", // 需要转换为二维码的内容
          width: 200,
          height: 200,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  margin: 0;
  padding: 0;
}
.headView {
  width: 900px;
  height: 100px;
  padding-left: 50px;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  line-height: 100px;
}
.headImageView {
  margin-left: 10px;
  width: 161px;
  height: 50px;
}
.headTitleView {
  font-size: 24px;
  margin-left: 30px;
}
.contentView {
  margin-bottom: 0px;
  /* background-color: olive; */
  min-width: 900px;
  height: 100%;
  display: flex;
  /* flex-wrap: wrap; */
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  /* justify-content: flex-start; */
  /* background-color: rgb(202, 198, 189); */
  /* background-color: rgb(229, 229, 238); */
}

.leftContent {
  margin-top: 10px;
  height: 900px;
  min-width: 300px;
  border: 1px solid #f0f0f0;
  overflow: scroll;
  background-color: white;
}
.leftContentItem {
  margin: 10px 0;
  width: 300px;
  background-color: #f0f0f0;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
}
/* .listView {
  width: 350px;
  height: 100%;
  margin-bottom: 50px;
  border: 5px solid rgba(160, 156, 156, 0.5);
  border-radius: 5px;
} */
.cell {
  margin: 0px;
  padding-left: 30px;
  padding-right: 30px;
  /* background-color: orange; */
}
.selectedCell {
  margin: 0px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: rgba(160, 156, 156, 0.5);
}
.cellContentView {
  margin: 0px;
  /* margin-top: 10px;
  margin-left: 30px;
  margin-right: 30px; */
  height: 35px;
  line-height: 35px;
  border-bottom: 1px solid rgba(160, 156, 156, 0.5);
}
.leftCellText {
  /* background-color: rgba(160, 156, 156, 0.5); */
  text-align: left;
  /* color: #493aff; */
  word-wrap: nowrap;
}
.selectedleftCellText {
  text-align: left;
  color: #493aff;
}

.itemContentView {
  /* margin-left: 30px;
  margin-right: 30px; */
  margin: 30px;
  /* width: 100%; */
  min-width: 600px;
  /* height: 100%; */
}
.titleName {
  margin: 0 0 20px;
  color: rgba(11, 12, 12, 1);
  font-size: 24px;
  text-align: left;
  display: flex;
  justify-items: flex-start;
  align-items: center;
  flex-direction: row;
}
.contentText {
  height: 100%;
  /* margin: 5px 0 10px 20px; */
  color: rgba(11, 12, 12, 0.7);
  font-size: 20px;
  white-space: pre-wrap;
  text-align: left;
  /* background-color: rgb(202, 198, 189); */
}
.contentText1 {
  /* background-color: orange; */
  height: 100%;
  color: rgba(11, 12, 12, 0.7);
  font-size: 20px;
  /* white-space: pre-wrap; */
  /* word-wrap: break-word; */
  text-align: left;
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
}
.qrcode {
  width: 200px;
  height: 200px;

  /* background-color: blue; */
}
</style>
