<template>
  <div>
    <div v-if="isload == false" class="loadContentView">
      <div class="loadView">
        <div>输入验证码</div>
        <input
          class="inputCode"
          type="password"
          autofocus=""
          placeholder="请输入验证码"
          :value="codeStr"
          @input="inputCode($event.target.value)"
        />
        <div class="codeCheckBtn" @click="checkCode()">确定</div>
      </div>
    </div>
    <div v-if="isload == true" class="qr-container">
      <div class="itemBack">
        <label class="titleLabel">门店名称：</label>
        <!-- oninput="if(value.length>11)value=value.slice(0,11)" -->
        <input
          class="inputText"
          type="text"
          placeholder="请输入"
          @keydown="checkInput($event)"
          v-model="depetName"
        />
      </div>
      <div class="itemBack">
        <label class="titleLabel">门店ID：</label>
        <input
          class="inputText"
          type="text"
          placeholder="请输入"
          @keydown="checkInput($event)"
          v-model="depetid"
        />
      </div>
      <div class="itemBack">
        <label class="titleLabel">二维码类型：</label>
        <input
          class="inputText"
          type="text"
          placeholder="请输入"
          @keydown="checkInput($event)"
          v-model="qrtype"
        />
      </div>

      <h2 class="demo-home__desc" @click="getqrcode()">生成二维码</h2>
      <img
        id="imgtest"
        style="width: 300px; height: 300px"
        src="https://www.thtlove.top/api/checkin_image?dept_id=001&time_type=1&dept_name=112233"
        alt=""
      />
      <div id="qrCode" ref="qrCodeDiv"></div>
    </div>
  </div>
</template>
<script>
// import { getQrcodeImage } from "../netWorking/index";
// import QRCodeTool from 'qrcodejs2'

// import QRCode from 'qrcodejs2'

export default {
  data() {
    return {
      depetName: "湖南公司长沙青竹湖分店",
      depetid: "5674",
      qrtype: "0",
      isload: false,
      codeStr: "",
    };
  },
  computed: {},
  mounted() {
    // this.getqrcode();
  },
  methods: {
    inputCode(text) {
      this.codeStr = text;
      console.log(text);
    },
    checkCode() {
      console.log(this.codeStr);
      if (this.codeStr == "001122") {
        this.isload = true;
      } else {
        this.codeStr = "";
      }
    },
    getqrcode() {
      console.log("00000000");
      document
        .getElementById("imgtest")
        .setAttribute(
          "src",
          "https://www.thtlove.top/api/checkin_image?dept_id=" +
            this.depetid +
            "&time_type=" +
            this.qrtype +
            "&dept_name=" +
            this.depetName
        );
    },
  },
};
</script>
<style lang="scss" scoped>
.qr-container {
  //   background-color: aquamarine;
}
.itemBack {
  margin-top: 20px;
  margin-left: 20px;
  //   background-color: aquamarine;
  height: 30px;
}
.titleLabel {
  font-size: 14px;
  width: 100px;
  height: 20px;
  //   background-color: aquamarine;
  text-align: right;
  line-height: 20px;
}
.inputText {
  //   margin: 20px 0px 0px 20px;
  width: 300px;
  font-size: 14px;
  height: 20px;
  margin-left: 20px;
  margin-right: 20px;
}
.demo-home__desc {
  margin: 20px 20px 20px;
  width: 100px;
  height: 20px;
  line-height: 20px;
  color: rgba(69, 90, 100, 0.6);
  font-size: 14px;
  //   background-color: aquamarine;
  text-align: center;
  border: rgb(137, 233, 217) 1px solid;
  border-radiusf: 10px;
  outline: none;
}
.flex {
  display: flex;
  .flex_1 {
    flex: 1;
    padding: 10px 16px;
  }
}
.loadContentView {
  display: flex;
  background-color: white;
  margin: 0px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.loadView {
  background-color: white;
  width: 300px;
  height: 200px;
  padding-top: 30px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
}
.inputCode {
  margin-top: 30px;
  width: 70%;
  height: 30px;
  font-size: 14px;
}
.codeCheckBtn {
  margin-top: 40px;
  margin-left: 25%;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  background-color: rgb(77, 132, 243);
  width: 50%;
}
</style>
