<template>
  <div class="mainView">
    <div @click="login()">登录</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "THT",
    };
  },
  created() {
    document.title = this.title;
  },
  mounted() {},
  methods: {
    login() {
      var fromurl = location.href;
      var url =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxdb8ffa10bd0ba5c9&redirect_uri=https://www.thtlove.top/api/wxapi&response_type=code&scope=snsapi_userinfo&state=snsapi_base#wechat_redirect";
      //snsapi_userinfo  授权框    snsapi_base 默认授权
      location.href = url;

      console.log(fromurl);
    },
  },
};
</script>

<style scoped>
.mainView {
  width: 100%;
  height: 100%;
}
</style>
