<template>
  <div class="">
    <h1 id="text" class="txt">
      {{ title }}
      <h1 class="txt2"></h1>
      <h1 class="txt3"></h1>
    </h1>
  </div>
</template>

<script>
export default {
  props: ["title", "fontSize"],
  data() {
    return { fontSize: "100px" };
  },
  mounted() {
    // 获取元素
    // const myElement = document.querySelector(".text");
    const myElement = document.getElementById("text");
    console.log(this.fontSize);
    // 修改行内样式
    myElement.style.fontSize = this.fontSize;

    console.log(myElement);
  },
  methods: {},
  components: {},
  computed: {},
};
</script>

<style lang="scss" scoped>
* {
  font-family: "Merienda", cursive;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  height: 100vh;
  background-color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
}
div {
  position: relative;
  overflow: hidden;
}
.txt {
  color: rgb(255, 255, 255);
  //   color: black;
  //   文本转换为大写
  //   text-transform: uppercase;
  //   font-size: 168px;
  background-color: rgb(0, 0, 0);
  // background-color: rgb(20, 19, 19);
  //   background-color: blue;
  //   无法通过鼠标或键盘选择相应元素的文本
  //   user-select: none;
  // -webkit-background-clip: text;
}
.txt::before {
  content: "";
  position: absolute;
  /*   text-shadow: 0 0 10px  rgb(255, 255, 255),
            0 0 10px  rgb(255, 255, 255),
            0 0 10px  rgb(255, 255, 255); */
  filter: blur(3px);
  mix-blend-mode: difference;
}
.txt2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgb(255, 0, 212),
    rgb(0, 119, 255),
    rgb(255, 187, 0),
    rgb(1, 255, 77)
  );
  mix-blend-mode: multiply;
}
.txt3 {
  position: absolute;
  top: -100%;
  left: -100%;
  right: 0;
  bottom: 0;
  background-image: radial-gradient(circle, white, black 30%);
  background-size: 25% 25%;

  mix-blend-mode: color-dodge;
  animation: shine 3s linear infinite;
}
@keyframes shine {
  100% {
    transform: translate(50%, 50%);
  }
}
</style>
