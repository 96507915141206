import { createRouter, createWebHashHistory } from "vue-router";
import { constantRouterMap } from "./router.config.js";

// const routerHistory = createWebHistory();
const routerHistory = createWebHashHistory();
const router = createRouter({
  history: routerHistory,
  routes: constantRouterMap,
});
export default router;
