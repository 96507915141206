<template>
  <div class="contentView">
    <!-- <van-button color="#7232dd">单色按钮</van-button>
    <van-button color="#7232dd" plain>单色按钮</van-button>
    <van-button color="linear-gradient(to right, #ff6034, #ee0a24)">
      渐变色按钮
    </van-button> -->
    <!-- <van-date-picker
      v-model="currentDate"
      title="选择日期"
      :min-date="minDate"
      :max-date="maxDate"
    /> -->
    <div v-if="isload == false" class="loadContentView">
      <div class="loadView">
        <div>输入验证码</div>
        <input
          class="inputCode"
          type="password"
          autofocus=""
          placeholder="请输入验证码"
          :value="codeStr"
          @input="inputCode($event.target.value)"
          @keyup.enter.native="checkCode()"
        />
        <div class="codeCheckBtn" @click="checkCode()">确定</div>
      </div>
    </div>
    <div v-if="isload == true" class="toolContentView">
      <div class="searchBackView">
        <div style="margin-left: 50px">姓名：</div>
        <input
          class="numberInput"
          type="text"
          autofocus=""
          placeholder="输入姓名"
          value=""
          @input="inputName($event.target.value)"
          @keyup.enter.native="searchaction()"
        />
        <div style="margin-left: 50px">工号：</div>
        <input
          class="numberInput"
          type="text"
          autofocus=""
          placeholder="输入工号"
          value=""
          @input="inputNumber($event.target.value)"
          @keyup.enter.native="searchaction()"
        />
        <div style="margin-left: 50px">UUID：</div>
        <input
          class="numberInput"
          type="text"
          autofocus=""
          placeholder="输入UUID"
          value=""
          @input="inputuuid($event.target.value)"
          @keyup.enter.native="searchaction()"
        />
        <div style="margin-left: 50px">权限：</div>
        <input
          class="numberInput"
          type="text"
          autofocus=""
          placeholder="输入权限系数"
          value=""
          @input="inputsearchlevel($event.target.value)"
          @keyup.enter.native="searchaction()"
        />
        <div class="numberSearchBtn" @click="searchaction()">搜索</div>
      </div>
      <div class="searchListView">
        <div class="listHeaderView">
          <div class="listColumn1">工号</div>
          <div class="listColumn2">姓名</div>
          <div class="listColumn3">UUID</div>
          <div class="listColumn4">权限级别</div>
          <div class="listColumn4">有效期</div>
          <div class="listColumn5">手机型号</div>
        </div>
        <div
          v-for="(item, index) in items"
          :key="item"
          class="searchListRowView"
        >
          <div class="listColumn1">
            {{ item.userNumber }}
          </div>
          <div class="listColumn2">
            {{ item.remark }}
          </div>
          <di class="listColumn3">
            {{ item.appuuid }}
          </di>
          <input
            class="listColumn4"
            type="text"
            autofocus=""
            placeholder="请输入级别"
            :value="item.level"
            @input="inputLevel($event.target.value, index)"
          />
          <!-- <van-date-picker
            class="dateTimeSelectView"
            v-model="currentDate"
            title="选择年月"
            :min-date="minDate"
            :max-date="maxDate"
            :formatter="formatter"
            :columns-type="columnsType"
          /> -->

          <div class="listColumn5">
            {{ item.phoneNumberName }}
          </div>

          <div class="updateBtn" @click="updateAction(index)">修改</div>

          <div class="deleteBtn" @click="deleteAction(index)">删除</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { checkPwd, getUserPower, updateUserPower } from "../netWorking/index";

import { ref } from "vue";
export default {
  setup() {
    const currentDate = ref(["2021", "01", "01"]);
    return {
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 5, 1),
      currentDate,
    };
  },
  data() {
    return {
      isload: false,
      codeStr: "",
      numberCode: null,
      items: null,
      inputNameStr: "",
      inputNumberStr: "",
      inputuuidStr: "",
      inputsearchlevelStr: "",
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(2021, 0, 17),
    };
  },
  created() {
    document.title = "权限管理";
  },
  mounted() {},
  methods: {
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    inputCode(text) {
      this.codeStr = text;
      console.log(text);
    },
    checkCode() {
      console.log(this.codeStr);

      checkPwd({
        pwd: this.codeStr,
        type: 1,
      })
        .then((data) => {
          if (data.code == 0) {
            this.isload = true;
          } else {
          }
        })
        .catch(function (err) {
          this.codeStr = "";
        });
    },
    inputName(text) {
      this.inputNameStr = text;
      console.log(text);
    },
    inputNumber(text) {
      this.inputNumberStr = text;
      console.log(text);
    },
    inputuuid(text) {
      this.inputuuidStr = text;
      console.log(text);
    },
    inputsearchlevel(text) {
      this.inputsearchlevelStr = text;
    },
    searchaction() {
      console.log("search");

      console.log(this.inputNumberStr);
      console.log(this.inputuuidStr);

      //   getUserPower({appuuid:'14b0acb51e5f4e0ab8fada5c9d7e3429'})

      getUserPower({
        appuuid: this.inputuuidStr,
        userNumber: this.inputNumberStr,
        name: this.inputNameStr,
        level: this.inputsearchlevelStr,
      })
        .then((data) => {
          console.log("data++++++++++++++++++++++");
          console.log(data);
          console.log(this.items);
          this.items = data["data"];
        })
        .catch(function (err) {
          console.log("error++++++++++++++++++++++");
          console.log(err);
          console.log("error");
        });
    },
    inputLevel(level, index) {
      this.items[index].level = level;
      console.log(this.items[index].level);
    },
    updateAction(index) {
      console.log(index);
      var prame = {
        appuuid: this.items[index].appuuid,
        level: this.items[index].level,
      };
      updateUserPower(prame)
        .then((dta) => {
          console.log(dta);
          alert("修改成功");
        })
        .catch(function (err) {
          console.log(err);
          alert("修改失败");
        });
    },
    deleteAction(index) {
      console.log(index);
    },
  },
};
</script>

<style>
.contentView {
  display: flex;
  background-color: rgb(248, 238, 238);
  margin: 0px;
  width: 100vw;
  height: 100vh;
  flex: 1 1 auto;
  flex-basis: 0;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
  flex-wrap: wrap;
}
.loadContentView {
  display: flex;
  /* 子元素背景透明，文字不透明 */
  /* background-color: rgb(104, 97, 97) + 5a; */

  /* 子元素背景不透明*/
  background-color: rgb(104, 97, 97, 0.5);
  margin: 0px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  /* opacity: 0.1; */
}
.loadView {
  background-color: white;
  width: 300px;
  height: 200px;
  padding-top: 30px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
}
.inputCode {
  margin-top: 30px;
  width: 70%;
  height: 30px;
  font-size: 14px;
}
.codeCheckBtn {
  margin-top: 40px;
  margin-left: 25%;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  background-color: rgb(77, 132, 243);
  width: 50%;
}

.toolContentView {
  background-color: rgb(240, 240, 240);
  width: 100%;
  height: 100%;
  min-width: 1400px;
}
.searchBackView {
  width: 100%;
  margin-top: 50px;
  height: 100px;
  display: flex;
  flex-direction: row;
  background-color: rgb(230, 230, 230);
  align-items: flex-start;
  /* justify-content: center; */
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.numberInput {
  height: 30px;
  width: 200px;
  font-size: 14px;
  margin-left: 4px;
}
.numberSearchBtn {
  width: 80px;
  height: 30px;
  margin-left: 20px;
  line-height: 30px;
  border-radius: 4px;
  color: white;
  align-self: center;
  background-color: rgb(77, 132, 243);
}
.searchListView {
  margin: 10px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: center;
  flex-wrap: nowrap;
}
.searchListRowView,
.listHeaderView {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  margin-top: 10px;
  height: 45px;
  line-height: 45px;
  padding-left: 30px;
  margin: 10px;
}
.searchListRowView {
  background-color: rgb(235, 235, 235);
  width: 100%;
}

.listColumn1,
.listColumn2,
.listColumn3,
.listColumn4,
.listColumn5 {
  margin-left: 30px;
  height: 30px;
  /* flex-wrap: nowrap; */
  white-space: nowrap;
  line-height: 30px;
  /* align-self: center; */
}
.dateTimeSelectView {
  margin-left: 30px;
  height: 30px;
  /* flex-wrap: nowrap; */
  white-space: nowrap;
  line-height: 30px;
  background-color: red;
  width: 80px;
}
.listColumn1 {
  width: 80px;
}
.listColumn2 {
  width: 80px;
}
.listColumn3 {
  width: 280px;
}
.listColumn4 {
  width: 80px;
}
.listColumn5 {
  width: 100px;
}
.updateBtn {
  width: 80px;
  height: 30px;
  margin-left: 150px;
  line-height: 30px;
  border-radius: 4px;
  color: white;
  align-self: center;
  background-color: rgb(77, 132, 243);
  white-space: nowrap;
}
.deleteBtn {
  width: 80px;
  height: 30px;
  margin-left: 50px;
  line-height: 30px;
  border-radius: 4px;
  color: white;
  align-self: center;
  background-color: rgb(192, 22, 36);
  white-space: nowrap;
}
.dateSelecteView {
  width: 300px;
  height: 300px;
}
</style>
