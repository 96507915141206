<template>
  <div class="containerView">
    <div class="headView">
      <img class="headImageView" src="../../static/image/ApiIcon.png" />
      <h1 style="margin-left: 60px">iOS 异常解决方法</h1>
    </div>
    <div class="contentInfoView">
      <div class="titleListView">
        <div
          v-for="(sectionItem, sectionIndex) in items"
          class="leftContentItem"
        >
          <div
            @click="selectSection(sectionIndex)"
            :class="[
              openSection == sectionIndex
                ? 'selectedListItemTitleView'
                : 'listItemTitleView',
            ]"
          >
            {{ sectionItem.group_name }}
          </div>

          <div
            v-if="openSection == sectionIndex"
            v-for="(item, index) in sectionItem.list"
            :key="item"
            class="leftContentItem"
          >
            <div
              @click="selectItem(index)"
              :class="[
                index == selectedItem
                  ? 'selectedSectionItemTitleView'
                  : 'sectionItemTitleView',
              ]"
            >
              {{ item.title }}
            </div>
          </div>
          <div
            style="
              width: 100%;
              height: 1px;
              background-color: rgb(240, 240, 233);
            "
          ></div>
        </div>
      </div>
      <div v-if="items" class="infoView">
        <span
          class="spanView"
          v-html="items[openSection].list[selectedItem].content"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import { getFixListData } from "../netWorking/index";
export default {
  data() {
    return {
      title: "百姓通常用处理方法",
      items: null,
      openSection: 0,
      selectedItem: 0,
    };
  },
  mounted() {
    // this.initDiv();
  },
  created() {
    getFixListData()
      .then((data) => {
        this.items = data.data;
      })
      .catch(function (err) {});
    this.items;

    document.title = this.title;

    let geturl = window.location.href;
    let getqyinfo = geturl.split("?")[1]; //qycode=1001&qyname=%E4%BC%81%E4%B8%9A%E5%BF%99   截取到参数部分
    let getqys = new URLSearchParams("?" + getqyinfo); //将参数放在URLSearchParams函数中
    this.openSection = getqys.get("section") ? getqys.get("section") : 0; //1001
    this.selectedItem = getqys.get("item") ? getqys.get("item") : 0; //1001
    this.updateHref();
  },
  methods: {
    selectSection(index) {
      this.openSection = index;
      this.selectedItem = 0;
      this.updateHref();
    },
    selectItem(index) {
      this.selectedItem = index;
      this.updateHref();
    },
    updateHref() {
      let geturl = window.location.href;
      let url = geturl.split("?")[0];
      window.location.href =
        url + "?section=" + this.openSection + "&item=" + this.selectedItem;
    },
  },
};
</script>

<style>
.containerView {
  min-width: 900px;
  height: 100%;
  background-color: white;
  /* display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start; */
}
.headView {
  width: 100%;
  height: 100px;
  background-color: rgb(240, 240, 233);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.headImageView {
  width: 161px;
  height: 50px;
  margin-left: 60px;
}
.contentInfoView {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: white;
}
.titleListView {
  margin-top: 10px;
  height: 900px;
  min-width: 300px;
  border: 1px solid #f0f0f0;
  overflow: scroll;
  background-color: white;
}
.leftContentItem {
  margin: 10px 0;
  width: 300px;
  /* background-color: #f0f0f0; */
  line-height: 30px;
  text-align: center;
  cursor: pointer;
}
.listItemTitleView {
  text-align: left;
  background-color: white;
  padding-left: 15px;
  font-size: 20px;
}
.selectedListItemTitleView {
  text-align: left;
  background-color: rgb(240, 240, 233);
  padding-left: 15px;
  font-size: 22px;
  font-weight: 700;
  /* margin-left: 15px; */
}
.sectionItemTitleView {
  text-align: left;
  background-color: white;
  font-size: 18px;
  padding-left: 30px;
}
.selectedSectionItemTitleView {
  text-align: left;
  background-color: white;
  font-size: 18px;
  padding-left: 30px;
  color: #4237d4;
}
.infoView {
  width: 100%;
  height: 100%;
  margin: 30px;
}
.spanView {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.iphoneImageView {
  width: 300px;
  height: 600px;
  object-fit: contain;
}
</style>
