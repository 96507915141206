<template>
  <div class="mainView">
    <canvas class="gradient_test" id="gradient_test"></canvas>
    <canvas class="canvas_cont" id="canvas_cont">Hello Canvas</canvas>

    <h1 id="text" class="txt">animation</h1>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.draw("gradient_test");
    this.drawcont();
  },
  methods: {
    draw(id) {
      var canvas = document.getElementById(id);

      var ctx = canvas.getContext("2d");
      //设置文字
      ctx.font = "bold 2.5rem '微软雅黑'";
      // 创建一个渐变
      var gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);

      gradient.addColorStop("0", "#56ecc7");
      gradient.addColorStop("0.5", "#9f5ad8");
      gradient.addColorStop("1", "#56ecc7");
      //应用渐变，空心文字
      ctx.strokeStyle = gradient;
      ctx.strokeText("animation", 0, 103, 502);
      // 填充一个渐变
      ctx.fillStyle = gradient;
      //创建一个文字
      ctx.fillText("animation", 0, 100, 500);
    },
    drawcont() {
      var canvas = document.getElementById("canvas_cont");
      console.log("canvas");
      console.log(canvas);
      //获取画布上下文
      var ctx = canvas.getContext("2d");
      //设置字体样式
      ctx.font = "30px 宋体";
      //   ctx.font = "bold 6.5rem '微软雅黑'";

      //设置线性渐变
      var gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
      //从0到整个区域的一半由黄渐变到蓝
      //从中间到最后由蓝渐变到红
      gradient.addColorStop("0", "yellow");
      gradient.addColorStop("0.5", "blue");
      gradient.addColorStop("1.0", "red");
      //应用渐变，空心文字
      ctx.strokeStyle = gradient;
      ctx.strokeText("Hello Canvas!!!", 0, 50, 503);
      //实心文字
      ctx.fillStyle = gradient;
      ctx.fillText("Hello World!!!", 0, 100, 500);
    },
  },
};
</script>

<style>
.gradient_test {
  width: 100%;
}
.canvas_cont {
  width: 100%;
  height: 800px;
  display: block; /*画布居中*/
}
@-webkit-keyframes gradient_animation {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
}

/* .text{
    webkit-linea
    background-color: ;
} */
</style>
