<template>
  <div class="containerView">
    <!-- <form action="/upload" method="POST" enctype="multipart/form-data">
      <div class="file">
        <input type="file" name="file" />
      </div>
      <div class="action">
        <input type="submit" value="提交" />
      </div>
    </form> -->

    <!-- <form>
      <input type="text" v-model="name" placeholder="请输入用户名" />
      <input type="text" v-model="age" placeholder="请输入年龄" />
      <input type="file" @change="getFile($event)" />
      <button @click="submitForm($event)">提交</button>
    </form> -->
    <h3>将需要上传的文件拖拽到此</h3>
    <div class="dropView" id="drop_area">
      <div v-for="(item, index) in filelist" class="fileCell">
        <div class="cellTextBackView">
          <div class="celltextView">
            {{ item.name }}
          </div>
          <div class="celltextView">
            {{ item.size }}
          </div>
          <input
            class="inputRemark"
            type="text"
            autofocus=""
            placeholder="请输入备注"
            :value="item.remark"
            @input="inputremark($event.target.value, index)"
          />
        </div>
        <van-loading v-if="item.isloading" type="spinner" size="24px" />

        <a v-if="item.path != ''" :href="item.path">
          <div>
            {{ item.path }}
          </div></a
        >
        <van-progress :percentage="item.speet" />
        <div class="deletBtn" @click="deleteAction(index)">删除</div>
      </div>
    </div>
    <div
      class="uploadClickView"
      v-if="filelist && filelist.length > 0"
      @click="uploadAction(0)"
    >
      开始上传
    </div>
  </div>
</template>

<script>
import { uploadImage } from "../netWorking/index";
export default {
  data() {
    return {
      title: "文件上传",
      name: "",
      age: "",
      file: "",
      filelist: null,
      remark: "",
    };
  },
  mounted() {
    // this.initDiv();
    this.multipleDragEvent();
  },
  created() {
    document.title = this.title;
  },
  methods: {
    getFile(event) {
      this.file = event.target.files[0];
      console.log(this.file);
    },
    submitForm(event) {
      event.preventDefault();
      let formData = new FormData();
      formData.append("name", this.name);
      formData.append("age", this.age);
      formData.append("file", this.file);

      uploadImage(formData)
        .then((data) => {
          console.log("上传成功");
        })
        .catch(function (err) {
          console.log("上传失败");
        });
    },
    inputremark(value, index) {
      this.filelist[index].hasuplod = false;
      this.filelist[index].remark = value;
    },

    //拖拽多个
    multipleDragEvent() {
      console.log("drop_area");
      let dropbox = document.getElementById("drop_area");
      dropbox.addEventListener("drop", this.eventDrop, false);
      dropbox.addEventListener("dragleave", function (e) {
        e.stopPropagation();
        e.preventDefault();
        dropbox.style.backgroundColor = "#fff";
      });
      dropbox.addEventListener("dragenter", function (e) {
        e.stopPropagation();
        e.preventDefault();
        dropbox.style.backgroundColor = "#fff";
      });
      dropbox.addEventListener("dragover", function (e) {
        e.stopPropagation();
        e.preventDefault();
        dropbox.style.backgroundColor = "#eee";
      });
    },
    eventDrop(e) {
      e.stopPropagation();
      e.preventDefault(); //必填
      this.documentList = [];
      this.documentPath = "";
      let fileData = e.dataTransfer.files;
      console.log("拖拽成功");
      if (!this.filelist) {
        this.filelist = [];
      }
      for (let i = 0; i < fileData.length; i++) {
        let f = fileData[i];
        let s = parseInt(f.size / 1024);

        this.filelist.push({
          name: f.name,
          size:
            s > 1024 ? parseInt(s / 1024).toString() + "M" : s.toString() + "K",
          file: f,
          isloading: false,
          remark: "",
          path: "",
          hasuplod: false,
          speet: 0,
        });
      }
    },

    //调用上传接口
    uploadFile(index) {
      let item = this.filelist[index];
      let fileData = item.file;
      let formData = new FormData();
      formData.append("file", fileData);
      formData.append("remark", item.remark);
      // formData.append("index", "index + 1");
      // uploadImage(formData)
      //   .then((data) => {
      //     console.log("上传成功" + index);
      //     // this.filelist.splice(index, 1);
      //     let item = this.filelist[index];
      //     item.isloading = false;
      //     this.uploadAction(index + 1);
      //     item.path = data.data.path;
      //     item.hasuplod = true;
      //     console.log(data.data.path);
      //   })
      //   .catch(function (err) {
      //   console.log("上传失败");
      //   this.filelist[index].isloading = false;
      //   this.uploadAction(index + 1);
      // });

      uploadImage(formData, index, this.uploadstate)
        .then((data) => {
          console.log("0000000000");
          console.log(data);
          if (typeof data == "number") {
            console.log("number0");
          } else {
            console.log("typeof data");

            console.log(typeof data);
            console.log("上传成功" + index);
            // this.filelist.splice(index, 1);
            let item = this.filelist[index];
            item.isloading = false;
            this.uploadAction(index + 1);
            item.path = data.data.path;
            item.hasuplod = true;
            console.log(data.data.path);
          }
        })
        .catch((err) => {
          console.log("上传失败");
          console.log(err);
          this.filelist[index].isloading = false;
          this.uploadAction(index + 1);
        });
    },
    uploadstate(data) {
      console.log("1111111");
      let index = data.index;
      if (data.state == "1") {
        let item = this.filelist[index];
        item.speet = data.progress.toFixed(2) * 100;
      } else if (data.state == "0") {
        // this.filelist.splice(index, 1);
        let item = this.filelist[index];
        item.isloading = false;
        this.uploadAction(index + 1);
        console.log(data);
        item.path = data.data.data.path;
        item.hasuplod = true;
      } else if (data.state == "-1") {
        console.log("上传失败");
        console.log(err);
        this.filelist[index].isloading = false;
        this.uploadAction(index + 1);
      }
    },
    deleteAction(index) {
      this.filelist.splice(index, 1);
    },
    uploadAction(index) {
      // for (let i = 0; i < this.filelist.length; i++) {
      if (index < this.filelist.length) {
        console.log("开始上传：" + index);
        let item = this.filelist[index];
        if (item.hasuplod) {
          this.uploadAction(index + 1);
        } else {
          item.isloading = true;
          this.uploadFile(index);
        }
      }
      // }
    },
  },
};
</script>

<style>
.inputRemark {
  height: 30px;
  width: 200px;
  font-size: 14px;
  margin-left: 40px;
}
.dropView {
  min-height: 400px;
  background-color: rgb(235, 235, 235);
}
.fileCell {
  margin-top: 5px;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
}
.cellTextBackView {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  background-color: white;
}
.celltextView {
  margin-left: 5px;
  height: 25px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: olive;
  line-height: 25px;
}
.deletBtn {
  width: 100px;
  height: 30px;
  line-height: 30px;
  color: white;
  background-color: red;
  margin-left: 50px;
  border-radius: 3px;
}
.uploadClickView {
  width: 100px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: 20px;
  background-color: royalblue;
  color: white;
}
</style>
