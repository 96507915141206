<template>
  <div class="rootcontentView">
    <!-- <div v-if="isload == false" class="loadContentView">
      <div class="loadView">
        <div class="headView">
          <img
            class="changeimg"
            @click="changepage()"
            src="../assets/load_change.png"
            alt=""
          />
        </div>
        <div v-if="codeload == true">
          <div>输入验证码</div>
          <input
            class="inputCode"
            type="password"
            autofocus=""
            placeholder="请输入验证码"
            :value="codeStr"
            @input="inputCodeAction($event.target.value)"
          />
          <div class="codeCheckBtn" @click="checkCode()">确定</div>
        </div>
        <div v-if="codeload == false">
          <div>{{ scanState }}</div>
          <div style="margin-top: 10px">{{ scantime }}秒后自动刷新</div>
          <img
            id="imgtest"
            style="width: 300px; height: 300px"
            :src="imgsrc"
            alt=""
          />
          <div class="codeCheckBtn" @click="getCodeToken()">刷新</div>
        </div>
      </div>
    </div> -->
    <ul class="listView">
      <template v-for="(item, index) in items">
        <li>
          <a
            class="cell"
            :href="item.start == 2 ? locationUrl + item.url : item.url"
            target="_self"
          >
            <SvgWord :title="item.name"></SvgWord>
          </a>
        </li>
      </template>
    </ul>
  </div>
  <!-- <div class="mainView">
    <canvas class="itemView" v-for="(item, index) in items" :id="itemId(item)">
    </canvas>
  </div> -->
</template>

<script>
import SvgWord from "@/components/view/svgword.vue";
import { getItemData, getToken, checkToken } from "../netWorking/index";
export default {
  components: { SvgWord },
  name: "HelloWorld000",
  data() {
    return {
      isload: true,
      codeload: true,
      extension: "",
      items: [],
      imgsrc: "",
      codeStr: "",
      tokenStr: "",
      timer: null,
      scanState: "百姓通扫码登录",
      scantime: 60,
      locationUrl: "",
    };
  },
  created() {
    document.title = "THT";
    console.log("this.items");
    // if (localStorage.getItem("token")) {
    //   this.isload = true;
    //   this.cleartimer();
    // }
    this.locationUrl = window.location.href;
    this.$nextTick(() => {
      if (this.codeload) {
        this.changepage();
      }
    });
  },
  mounted() {
    getItemData()
      .then((data) => {
        this.items = data["data"];
        // setTimeout(() => {
        //   this.forItem();
        // }, 500);
        this.$nextTick(() => {
          this.forItem();
        });
      })
      .catch(function (err) {
        console.log(err);
        console.log("error");
      });

    // localStorage.setItem("token", "");
    // this.sanJiaoXing();
    // this.jianBian();
  },
  methods: {
    inputCodeAction(value) {
      this.codeStr = value;
    },
    changepage() {
      this.codeload = !this.codeload;
      if (this.codeload) {
        this.codeStr = "";
        this.cleartimer();
      } else {
        this.getCodeToken();
        this.timer = setInterval(() => {
          this.timeraction();
        }, 1000);
      }
    },
    timeraction() {
      if (!this.isload && !this.codeload) {
        this.checkCode();
        this.scantime--;
        if (this.scantime <= 0) {
          this.getCodeToken();
        }
      } else {
        this.cleartimer();
      }
    },
    //生命周期若已设置定时器，需清空定时器beforeDestroy()
    cleartimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
    getCodeToken() {
      var prame = {
        type: this.codeload ? "1" : "2",
      };
      getToken(prame)
        .then((data) => {
          this.tokenStr = data.token;

          if (process.env.NODE_ENV === "development") {
            this.imgsrc =
              "https://www.thtlove.top/api/creatqrcode?string=http://10.0.213.101:1234/%23/AuthorizedLogin?token=" +
              data.token;
            // alert("开发环境");
          } else {
            // alert("生产环境");
            this.imgsrc =
              "https://www.thtlove.top/api/creatqrcode?string=https://www.thtlove.top/%23/AuthorizedLogin?token=" +
              data.token;
          }
          // console.log(this.imgsrc);
          this.scantime = 60;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkCode() {
      var prame = {
        type: this.codeload ? "1" : "2",
        token: this.codeload ? this.codeStr : this.tokenStr,
      };
      console.log(prame);
      checkToken(prame)
        .then((data) => {
          console.log(data);
          if (this.codeload) {
            if (data.state == "1") {
              this.cleartimer();
              this.isload = true;
              // this.$store.state.isOnline = true;
              localStorage.setItem("token", this.codeStr);
            } else {
              alert("验证码错误");
            }
          } else {
            if (data.state == "1") {
              this.scanState = "百姓通扫码已扫码";
            } else if (data.state == "2") {
              this.cleartimer();
              this.isload = true;
              // this.$store.state.isOnline = true;
              localStorage.setItem("token", this.tokenStr);
            }
          }
        })
        .catch((err) => {});
    },

    forItem() {
      for (let index = 0; index < this.items.length; index++) {
        const element = this.items[index];

        // this.sanJiaoXing(element);
      }
    },
    itemId(item) {
      return "id" + item.sid;
    },
    jumpAction(url) {
      var that = this;
      axios
        .post(url, "")
        .then(function (response) {
          console.log(response);
          if (response.data == "ok") {
            // that.$router.push({path: './Index'})
          }
        })
        .error(function (error) {
          console.log(error);
        });
    },
    // ziTi() {
    //   let canvas = document.getElementById("myCanvas");
    //   let ctx = canvas.getContext("2d");
    //   ctx.font = "30px Arial"; // 设置字体及大小
    //   ctx.fillText("Hello World 泥巴巴", 10, 150); // 实心文字
    //   ctx.strokeText("Hello World 泥巴巴", 10, 180); // 空心文字
    // },
    // xianDuan() {
    //   var canvas = document.getElementById("myCanvas");
    //   var ctx = canvas.getContext("2d");
    //   ctx.moveTo(0, 0); // moveTo(x,y) 定义线条开始坐标
    //   ctx.lineTo(200, 100); // lineTo(x,y) 定义线条结束坐标
    //   ctx.stroke();
    // },
    // sanJiaoXing(dic) {
    //   var canvas = document.getElementById(this.itemId(dic));
    //   var ctx = canvas.getContext("2d");
    //   ctx.beginPath();
    //   ctx.moveTo(50, 50);
    //   ctx.lineTo(10, 200);
    //   ctx.lineTo(100, 200);
    //   ctx.lineTo(130, 50);
    //   ctx.lineTo(50, 50);
    //   // ctx.fillStyle = "#FF5062";
    //   var grd = ctx.createLinearGradient(0, 0, 150, 0); // 创建渐变轴 设置渐变
    //   grd.addColorStop(0, "red"); // 渐变起始颜色
    //   grd.addColorStop(1, "rgb(189, 187, 184)"); // 渐变结束色
    //   ctx.fillStyle = grd; // 填充色
    //   ctx.fill();

    //   ctx.fillStyle = "#FF5062";
    //   ctx.font = "30px Arial"; // 设置字体及大小
    //   ctx.fillText(dic.name, 10, 50); // 实心文字

    //   // ctx.stroke(); // 无填充色
    // },
    // jianBian() {
    //   let canvas = document.getElementById("myCanvas");
    //   let ctx = canvas.getContext("2d");
    //   var grd = ctx.createLinearGradient(0, 0, 200, 0); // 设置渐变
    //   grd.addColorStop(0, "red"); // 渐变起始颜色
    //   grd.addColorStop(1, "orange"); // 渐变结束色
    //   ctx.fillStyle = grd; // 填充色
    //   ctx.fillRect(10, 10, 200, 200); // (x,y,width,height) 矩形坐标和宽高
    // },
  },

  metaInfo() {
    //nuxt.js中metaInfo==head
    return {
      title: this.extension.f_extension_title,
      meta: [
        {
          hid: "keywords",
          name: "keywords",
          content: this.extension.f_extension_keywords,
        },
        {
          hid: "description",
          name: "description",
          content: this.extension.f_extension_description,
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.rootcontentView {
  background-color: white;
  margin: 0px;
  padding: 0;
  width: 100%;
  /* height: 100%; */

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.listView {
  display: flex;
  padding-top: 50px;
  width: 100%;
  flex-direction: column;
  align-items: center;
}
.cell {
  margin-top: 20px;
  /* height: 50px; */
  /* // 每行放几组，这里就除于几 */
  width: 700px;
  float: left; /* 向左浮动， */
  text-align: center;
  display: flex;
  /* flex-direction: column; */
  /* justify-items: center; */
  justify-content: center;
  /* align-items: center; */
}
li {
  /* display: inline-block; */
  /* margin: auto; */
  text-align: left;
  line-height: 100%;

  /*float: left; /* 向左浮动， */
  /* background-color: rgb(156, 151, 149); */
}
.testview {
  width: 100%;
  /* height: 100%; */
  background-color: white;
}
.title {
  /* background-color: olive; */
  font-size: 24px;
  /* font-family: "DBLCDTempBlack"; */
  font-family: "zapfino";
}
.subtext {
  margin-top: -20px;
  font-size: 12px;
  font-family: "DBLCDTempBlack";
  /* font-family: "zapfino"; */
}
.backClick {
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 100px solid red;
  border-top: 100px solid orange;
}
.myCanvas {
  background-color: rgb(189, 187, 184);
}
.itemView {
  width: 250px;
  height: 200px;
  background-color: orchid;
}
.mainView {
  width: 500px;
  height: 400px;
  background-color: rgb(172, 163, 171);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
}

.loadContentView {
  /* display: flex; */
  /* 子元素背景透明，文字不透明 */
  /* background-color: rgb(104, 97, 97) + 5a; */

  /* 子元素背景不透明*/
  /* background-color: rgb(104, 97, 97, 0.5); */
  margin: 0px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgb(129, 123, 123);
  /* opacity: 0.1; */
}
.loadView {
  background-color: white;
  width: 300px;
  height: 300px;
  padding-top: 0px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
}
.headView {
  flex-direction: row;
  justify-content: end;
  align-items: end;
  height: 30px;
  background-color: white;
}
.changeimg {
  width: 30px;
  height: 30px;
}
.inputCode {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 70%;
  height: 30px;
  font-size: 14px;
}
.codeCheckBtn {
  margin-top: 40px;
  margin-left: 25%;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  background-color: rgb(77, 132, 243);
  width: 50%;
}

.navflipbook {
  transform-style: preserve-3d;
  position: relative;
}
.page {
  width: 300px;
  height: 550px;
  border: 1px solid aquamarine;
  text-align: center;
  line-height: 550px;
  font-size: 16px;
  transition: 0.6s;
  transform-origin: left;
  position: absolute;
  top: calc(50vh - 260px);
  left: calc(50vw - 150px);
  /* img {
    width: 100%;
    height: 100%;
  } */
}
</style>
