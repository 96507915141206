<template>
  <div class="mainView">
    <div v-if="userinfo != null">
      <div class="infoBackView">
        <div style="display: flex; flex-direction: row">
          <div class="infoView">申请人：{{ userinfo.name }}</div>
          <div class="infoView">工号：{{ userinfo.login_id }}</div>
        </div>

        <div style="display: flex; flex-direction: row">
          <div class="infoView">部门：{{ userinfo.department_name }}</div>
          <!-- <div style="display: flex; flex-direction: row; width: 250px">
            <div class="infoView">
              {{ applyType == 1 ? "测试环境" : "生产环境" }}
            </div>
            <van-switch style="margin-left: 15px" v-model="checked" />
          </div> -->
        </div>
      </div>
      <div style="margin-top: 15px">
        <van-tabs v-model:active="active" type="card">
          <van-tab title="生产环境">
            <!-- <div v-if="userinfo.job_name.includes('测试', 0) == true"> -->
            <div v-if="userinfo.job_name.includes('测试', 0) == false">
              <!-- <van-cell-group inset> -->
              <van-field
                class="numberInputView"
                v-model="inputNumber"
                rows="1"
                autosize
                label="登录工号:"
                type="textarea"
                placeholder="多个工号换行输入，最多输入5个"
              />
              <!-- </van-cell-group> -->
              <van-button
                style="margin-top: 245px"
                type="primary"
                @click="applyAction(2)"
                >立即申请
              </van-button>
              <div class="msgLabel">生产环境申请后有效期1小时</div>
            </div>
            <div v-if="userinfo.job_name.includes('测试', 0) == false">
              <div class="msgLabel">仅供测试人员使用</div>
            </div>
          </van-tab>
          <van-tab title="测试环境">
            <div>
              <van-button
                style="margin-top: 245px"
                type="primary"
                @click="applyAction(1)"
                >立即申请
              </van-button>
              <div class="msgLabel">测试环境申请后有效期24小时</div>
            </div>
          </van-tab>
        </van-tabs>
        <!-- <div class="infoView">账号登录环境</div>
        <van-switch size="22px" v-model="checked" /> -->
      </div>
    </div>
    <div v-if="isBXT == false">
      <div style="margin-top: 50px">请使用百姓通扫码授权登录</div>
    </div>
    <div v-if="loginerr == true">
      <div style="margin-top: 50px">请使用企业微信登录百姓通</div>
    </div>
  </div>
</template>

<script>
import { applyForNumberLogin } from "../netWorking/index";
export default {
  data() {
    return {
      title: "账号登录申请",
      isBXT: false,
      loginerr: false,
      userinfo: null,
      applyType: 1,
      checked: true,
      inputNumber: "",
    };
  },
  created() {
    document.title = this.title;
    if (navigator.userAgent.indexOf("LBX BXT") != -1) {
      this.isBXT = true;
      setTimeout(() => {
        this.getuuid();
      }, 500);
    }
  },
  mounted() {
    // this.getuuid();
    console.log("mounted");
  },
  beforeUpdate() {
    console.log("beforeUpdate");
  },
  methods: {
    getuuid() {
      var param = {
        keys: [
          "logintype",
          "name",
          "avatar",
          "login_id",
          "department_name",
          "job_name",
          "appuuid",
        ],
      };
      bxt.getUserInfo(param, (info) => {
        var data = JSON.parse(info);
        this.userinfo = data;
        if (data.logintype == 2) {
          this.loginerr = true;
          var parameters = { message: "请使用企业微信登录百姓通", type: "4" };
          bxt.showHud(parameters);
        } else {
          this.userinfo = data;
          console.log("clickAction");
        }
      });
    },
    applyAction(type) {
      console.log(this.inputNumber);
      var prame = {
        name: this.userinfo.name,
        login_id: this.userinfo.login_id,
        appuuid: this.userinfo.appuuid,
        numbers: this.inputNumber,
        type: type,
      };
      applyForNumberLogin(prame)
        .then((data) => {
          console.log(data);
          if (data.code == "-1") {
            console.log(data.code);
            var parameters = { message: data.msg, type: "2" };
            bxt.showHud(parameters);
            // bxt.backHome();
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.mainView {
  width: 100%;
  height: 100%;
}
.infoBackView {
  width: 100%;
  /* height: 60px; */
  padding: 0px;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  /* align-items: flex-start; */
  background-color: rgb(228, 228, 224);
  justify-content: left;
}
.rowView {
  display: flex;
  flex-direction: row;
  justify-content: left;
}
.columnView {
  display: flex;
  flex-direction: column;
  justify-content: left;
}
.infoView {
  /* width: 100%; */
  height: 40px;
  line-height: 40px;
  margin: 0px;
  margin-left: 15px;
  text-align: left;
}

.msgLabel {
  margin: 0px;
  margin-top: 20px;
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  color: orangered;
  /* background-color: orangered; */
}

.numberInputView {
  /* margin: 10px; */
  /* margin-left: 15px;
  margin-right: 15px; */
  min-height: 100px;
  max-height: 400px;
  background-color: wheat;
  width: 100%;
}
</style>
