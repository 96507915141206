<template>
  <div class="main">
    <div class="actionView">
      <div class="rowView">
        <div class="titleView">API名称：</div>
        <input class="inputTextView" v-model="api_name" />
      </div>
      <div class="rowView">
        <div class="titleView">API描述：</div>
        <input class="inputTextView" v-model="api_name" />
      </div>
      <div class="rowView">
        <div class="titleView">上级API：</div>
        <select class="selectList" v-model="selectedSupModel" id="fruits">
          <option class="selectListoption" disabled value="">请选择</option>
          <option
            class="selectListoption"
            v-for="fruit in fruits"
            :key="fruit.num"
            :value="fruit.num"
          >
            {{ fruit.title }}
          </option>
        </select>
        <!-- <div class="titleView">编号：</div>
        <input class="inputTextView" v-model="new_num" /> -->
      </div>
      <div class="rowView">
        <div class="titleView">调用示例：</div>
        <demoCodeInput
          class="codeInputView"
          v-model="demoStr"
          @input-change="inputDemoStr"
          ref="inputdata"
          :bgColor="parentBgColor"
        />
      </div>
      <div class="rowView">
        <div class="titleView">入参说明：</div>
        <!-- <input type="text" v-model="demo" /> -->
        <demoCodeInput
          class="codeInputView"
          @input-change="inputIn_parameter"
          :bgColor="parentBgColor"
        />
      </div>
      <div class="rowView">
        <div class="titleView">回参说明：</div>
        <!-- <input type="text" v-model="demo" /> -->

        <demoCodeInput
          class="codeInputView"
          @input-change="inputOutput_parameter"
          :bgColor="parentBgColor"
        />
      </div>
      <div
        class="rowView"
        :style="{
          'justify-content': 'center',
        }"
      >
        <div class="addBtn" @click="uploadData">添加</div>
      </div>
    </div>
  </div>
</template>

<script>
import demoCodeInput from "./view/codeInputView.vue";

import { getAPITitleList } from "../netWorking/index";
export default {
  data() {
    return {
      api_name: "",
      demoStr: "",
      in_parameter: "",
      output_parameter: "",
      parentBgColor: "rgb(39, 39, 39)",
      selectedSupModel: "0",
      fruits: [{ num: 0, title: "一级API" }],
      new_num: "",
    };
  },
  created() {
    document.title = "新增Api";
    this.getAPIlistData();
  },
  components: {
    demoCodeInput,
  },
  computed: {
    // codeLines() {
    //   console.log(this.demoStr);
    //   return this.demoStr.split("\n");
    // },
  },
  methods: {
    getAPIlistData() {
      var prame = {};
      console.log(prame);
      getAPITitleList(prame)
        .then((data) => {
          this.fruits = this.fruits.concat(data["data"]);
          console.log(this.fruits);
        })
        .catch((err) => {});
    },

    inputDemoStr(str) {
      this.demoStr = str;
    },
    inputIn_parameter(str) {
      this.in_parameter = str;
    },
    inputOutput_parameter(str) {
      this.output_parameter = str;
    },

    uploadData() {
      //   console.log("this.$refs.inputdata.input_str");
      //   console.log(this.$refs.inputdata.input_str);

      console.log(this.selectedSupModel);

      //   console.log(this.api_name);
      //   console.log(this.demoStr);
      //   console.log(this.in_parameter);
      //   console.log(this.output_parameter);
    },
  },
};
</script>

<style>
.main {
  height: 100%;
}
.actionView {
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}
.rowView {
  flex-direction: row;
  align-items: flex-start;
  /* justify-content: center; */

  /* background-color: rgb(223, 220, 216); */
  margin-top: 20px;
  margin-left: 50px;
  margin-right: 50px;
  width: 100%;
  /* height: 200px; */
}
.titleView {
  width: 100px;
  text-align: left;
}
.inputTextView {
  width: 800px;
  height: 30px;
}
.selectList {
  width: 200px;
  height: 30px;
  font-size: 18px;
  /* padding: 20px; */
  -webkit-appearance: none;

  border-color: rgb(193, 196, 202);
  padding-left: 10px;
}
.selectListoption {
  height: 50px;
}

.codeInputView {
  width: 800px;

  min-height: 150px;
  max-height: 400px;
}

.addBtn {
  margin-top: 100px;
  width: 300px;
  height: 40px;
  background-color: rgb(37, 106, 233);
  border-width: 2px;
  border-color: rgb(74, 129, 233);
  /* border-color: rgb(23, 25, 29); */
  border-style: solid;
  border-radius: 20px;
  line-height: 40px;
  color: white;
  font-size: 18px;
}
</style>
