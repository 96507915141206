<template>
  <div class="mainView">
    <div v-if="isBXT == true && token != ''">
      <!-- <h2 style="margin-top: 20px">授权登录</h2> -->
      <div style="margin-top: 60px; font-size: 24px">{{ name }}</div>
      <img :src="imageurl" alt="" class="headerImageView" />
      <div style="margin-top: 50px; font-size: 18px">
        即将登陆百姓通开放平台
      </div>
      <van-button
        style="margin-top: 300px; height: 40px; width: 120px"
        type="primary"
        @click="clickAction()"
        >授权登录</van-button
      >
    </div>
    <div v-if="isBXT == false">
      <div style="margin-top: 50px">请使用百姓通扫码授权登录</div>
    </div>
    <div v-if="token == ''">
      <div style="margin-top: 50px">未获取到 token</div>
    </div>
  </div>
</template>

<script>
import { updateToken } from "../netWorking/index";
export default {
  data() {
    return {
      title: "百姓通授权登录",
      isBXT: false,
      name: "",
      imageurl: "",
      number: "",
      token: "",
      tokenState: "0", // 1 过期， 2 无效
    };
  },
  created() {
    document.title = this.title;
    if (navigator.userAgent.indexOf("LBX BXT") != -1) {
      this.isBXT = true;
      let geturl = window.location.href;
      let getqyinfo = geturl.split("?")[1]; //qycode=1001&qyname=%E4%BC%81%E4%B8%9A%E5%BF%99   截取到参数部分
      let getqys = new URLSearchParams("?" + getqyinfo); //将参数放在URLSearchParams函数中
      let getqycode = getqys.get("token"); //1001
      if (getqycode) {
        this.token = getqycode;
      } else {
      }
    } else {
      this.isBXT = false;
    }
  },
  mounted() {
    setTimeout(() => {
      var param = { keys: ["name", "avatar", "login_id"] };
      bxt.getUserInfo(param, (info) => {
        var data = JSON.parse(info);
        this.name = data.name;
        this.imageurl = data.avatar;
        this.number = data.login_id;
        console.log("data.name");
        this.updataState("1");
        this.$nextTick(() => {});
      });
    }, 500);
  },
  methods: {
    clickAction() {
      var param = { keys: ["logintype"] };
      bxt.getUserInfo(param, (info) => {
        var data = JSON.parse(info);
        if (data.logintype == 2) {
          var parameters = { message: "请使用企业微信登录百姓通", type: "4" };
          bxt.showHud(parameters);
        } else {
          this.updataState("2");
          console.log("clickAction");
        }
      });
    },
    updataState(state) {
      console.log("updateToken");
      var prame = {
        state: state,
        token: this.token,
        user: this.number,
      };
      updateToken(prame)
        .then((data) => {
          console.log(data);
          if (data.code == "-1") {
            console.log(data.code);
            var parameters = { message: data.msg, type: "2" };
            bxt.showHud(parameters);
            bxt.backHome();
          } else {
            if (data.data == "2") {
              var parameters = { message: "授权成功", type: "1" };
              bxt.showHud(parameters);
              setTimeout(() => {
                bxt.backHome();
              }, 500);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.mainView {
  width: 100%;
  height: 100%;
}
.headerImageView {
  margin-top: 10px;
  width: 60px;
  height: 60px;
  border-radius: 30px;
}
</style>
