<template>
  <div class="mainView">
    <!-- <h1>CUSTOM</h1> -->

    <!-- <div style="background: rgb(240 240 240)"> -->
    <SvgWord title="CUSTOM"></SvgWord>
    <!-- <merry_go_round title="CUSTOMddd" fontSize="100px"></merry_go_round> -->
    <canvasTextView></canvasTextView>
    <!-- </div> -->
  </div>
</template>

<script>
// 导入需要的组件
import SvgWord from "@/components/view/svgword.vue";
import merry_go_round from "@/components/view/merry-go-round.vue";
import canvasTextView from "@/components/view/canvasTextView.vue";
export default {
  // 注册组件
  components: { SvgWord, merry_go_round, canvasTextView },
  data() {
    return {
      title: "CUDTOM",
    };
  },
  created() {
    document.title = this.title;
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.mainView {
  width: 100%;
  height: 100%;
}
</style>
