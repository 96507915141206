// 这个JS文件就是专门用于管理请求各种接口地址的
import netWork from "./netWork";
import Network from "./netWork";

// 获取权限列表
export const checkPwd = (prameDic) => Network.get("/api/check_pwd", prameDic);
// 封装各种接口请求
// 首页item
export const getItemData = () => Network.get("/api/get_web_root_items", {});

// 获取权限列表
export const getUserPower = (prameDic) =>
  Network.get("/api/powerlist", prameDic);

// 修改权限
export const updateUserPower = (prameDic) =>
  Network.post("/api/updatePower", prameDic);

//获取异常处理数据
export const getFixListData = () => netWork.get("/api/fixListData");

//获取二维码图片
export const getQrcodeImage = (prameDic) =>
  Network.post("/api/checkin_image", prameDic);

//获取API
export const getAPITitleList = (prameDic) =>
  Network.post("/api/newApi/getApiDataWitchSupNum", prameDic);

//上传图片

// export const uploadImage = (prameDic) =>
// Network.post("/api/upload_image", prameDic);
export const uploadImage = (prameDic, index, callback) =>
  Network.upload("/api/upload_image", prameDic, index, callback);

//获取token  type 1codeload 2qrscan
export const getToken = (prameDic) => Network.get("/api/getToken", prameDic);

//校验token
export const checkToken = (prameDic) => Network.get("/api/checkCode", prameDic);

//修改token状态
export const updateToken = (prameDic) =>
  Network.get("/api/updateToken", prameDic);

export const applyForNumberLogin = (prameDic) =>
  Network.post("/api/applyForNumberLogin", prameDic);

export const uploadExcel = (prameDic, index, callback) =>
  Network.upload("/api/examination/upload", prameDic, index, callback);

export const getTestList = (prameDic) =>
  Network.get("/api/examination/get_group", prameDic);

export const deletTestList = (prameDic) =>
  Network.get("/api/examination/delet_group", prameDic);

//获取API
export const getAPPInfo = (prameDic) =>
  Network.post("/api/getpgyerAppInfo", prameDic);
