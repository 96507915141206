<template>
  <div class="mainView"></div>
</template>

<script>
export default {
  data() {
    return {
      title: "百姓通下载",
    };
  },
  created() {
    document.title = this.title;
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.mainView {
  width: 100%;
  height: 100%;
}
</style>
