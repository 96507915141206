// import { createApp } from "vue";
// import App from "./App.vue";

// createApp(App).mount("#app");

import { createApp } from "vue";
import App from "./App.vue";
import Vconsole from "vconsole";
import router from "./router"; // 引入router
import store from "./store";
import Vuex from "vuex";
// import VueCookies from "vue-cookies";
// 1. 引入你需要的组件
import {
  DatePicker,
  Button,
  Field,
  CellGroup,
  List,
  Loading,
  Circle,
  Progress,
  Switch,
  Tab,
  Tabs,
  Toast,
} from "vant";
// 2. 引入组件样式
import "vant/lib/index.css";

import "./assets/main.css";

const app = createApp(App);

// 3. 注册你需要的组件
app.use(DatePicker);

// 方式一. 通过 app.use 注册
// 注册完成后，在模板中通过 <van-button> 或 <VanButton> 标签来使用按钮组件
app.use(Button);
app.use(Field);
app.use(CellGroup);
app.use(List);
app.use(Loading);
app.use(Circle);
app.use(Progress);
app.use(Switch);
app.use(Tab);
app.use(Tabs);
app.use(Toast);

app.use(store);
app.use(Vuex);

// app.use(VueCookies);
router.beforeEach((to, from, next) => {
  //需要权限
  // if (to.meta.requireAuth) {
  //   //判断当前是否拥有权限
  //   if (localStorage.getItem("token")) {
  //     next();
  //   } else {
  //     // 无权，跳转登录
  //     next({
  //       path: "/",
  //       query: { redirect: from.fullPath },
  //     });
  //   }
  // } else {
  // 不需要权限，直接访问
  next();
  // }
});
// 填的值1d为一天,1h为一小时,1min为一分钟,1s为1秒
// this.$cookies.config("30s");

// createApp(App).use(Button).mount("#app");
app.use(router).mount("#app");
// createApp(App).use(router).mount("#app");

if (process.env.NODE_ENV === "development") {
  new Vconsole();
}

// main.js
// app.prototype.testUrl = "http://10.0.2.21";

app.config.globalProperties.testUrl = "http://10.0.221.163";
