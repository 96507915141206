<template>
  <div class="headView">
    <img class="headImageView" src="./assets/headIcon.png" />
    <!-- <div class="headTitleView">iOS原生Api</div> -->
  </div>
  <router-view></router-view>
    <div class="footView">
      <div class="footTextView">
        <div class="footItemTitleView">备案号：</div>
        <a class="footItemTextView" target="_blank" rel="nofollow" href="https://beian.miit.gov.cn/">湘ICP备2023003791号-1</a>
    </div> 
       </div>
</template>

<script>
// import HelloWorld from "./components/HelloWorld.vue";

export default {
  // name: "App",
  components: {
    // HelloWorld,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 5px;
  height: 100%;
  /* width: 100%; */
  /* background-color: rgb(227, 230, 222); */
}
.footView {
  width: 100%;
  display: flex;
  /* justify-items: center; */
  justify-content: center;
}
.footTextView{
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.footItemTitleView,.footItemTextView{
color: #999;
font-size: 12px;
}
</style>
