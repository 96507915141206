/**
 * 基础路由
 * @type { *[] }
 */

import HelloWorld from "@/components/HelloWorld";
import rootView from "../components/rootView.vue";
import test from "../components/test.vue";
import apiFile from "../components/apiFile.vue";
import testDemo from "../components/apiDemo.vue";
import tableTool from "../components/TableTool.vue";
import qrcode from "../components/QRcode.vue";
import canvasTest from "../components/canvasTest.vue";
import tool from "../components/bxtTool.vue";
import upload from "../components/uploadFile.vue";
import HotFixRelease from "../components/HotFixRelease.vue";

import appdownload from "../components/ipa_download/appdownload.vue";
import AuthorizedLogin from "../components/BXTAuthorizedLogin.vue";
import ApplyFor from "../components/ApplyForNumberLogin.vue";

import wxlogin from "../components/WeiXin/WXCheckin.vue";

import faceBack from "../components/faceBack.vue";

import CUSTOM from "../components/CUSTOM.vue";
import addApiData from "../components/addAPIData.vue";

import importModel from "../components/template/ImportTemplate.vue";

import appInstall from "../components/appInstall.vue";

export const constantRouterMap = [
  {
    path: "/",
    // redirect: "tableTool",
    component: rootView,
    keepAlive: false,
    // component: apiFile,
  },
  {
    path: "/AuthorizedLogin", //url后面路径
    name: "AuthorizedLogin",
    component: AuthorizedLogin,
    meta: {
      // requireAuth: true,
      keepAlive: false,
    },
  },

  // {
  //   path: "/bxt", //url后面路径
  //   component: AuthorizedLogin,
  // },upload

  {
    path: "/test", //url后面路径
    name: "test",
    component: test,
    meta: {
      requireAuth: false,
      keepAlive: false,
    },
  },
  {
    path: "/CUSTOM", //CUSTOM
    name: "CUSTOM",
    component: CUSTOM,
    meta: {
      requireAuth: false,
      keepAlive: false,
    },
  },
  {
    path: "/CUSTOM", //CUSTOM
    name: "CUSTOM",
    component: CUSTOM,
    meta: {
      requireAuth: false,
      keepAlive: false,
    },
  },
  {
    path: "/importModel", //url后面路径
    name: "importModel",
    component: importModel,
    meta: {
      requireAuth: false,
      keepAlive: false,
    },
  },
  {
    path: "/HotFixRelease", //热更新文件上传
    name: "HotFixRelease",
    component: HotFixRelease,
    meta: {
      requireAuth: false,
      keepAlive: false,
    },
  },
  {
    path: "/ApplyFor", //账号登录申请
    name: "ApplyFor",
    component: ApplyFor,
    meta: {
      requireAuth: false,
      keepAlive: false,
    },
  },
  {
    path: "/upload", //url后面路径
    name: "upload",
    component: upload,
    meta: {
      requireAuth: false,
      keepAlive: false,
    },
  },
  {
    path: "/appdownload", //url后面路径
    name: "appdownload",
    component: appdownload,
    meta: {
      requireAuth: false,
      keepAlive: false,
    },
  },
  {
    path: "/appdownload", //url后面路径
    name: "appdownload",
    component: appdownload,
    meta: {
      requireAuth: false,
      keepAlive: false,
    },
  },
  {
    path: "/wxlogin", //url后面路径
    name: "wxlogin",
    component: wxlogin,
    meta: {
      requireAuth: false,
      keepAlive: false,
    },
  },
  {
    path: "/tool", //url后面路径
    name: "tool",
    component: tool,
    meta: {
      requireAuth: false,
      keepAlive: false,
    },
  },
  {
    path: "/canvasTest", //url后面路径
    name: "canvasTest",
    component: canvasTest,
    meta: {
      title: "canvasTest",
      keepAlive: false,
    },
  },
  {
    path: "/apifile", //url后面路径
    name: "apifile",
    component: apiFile,
    meta: {
      requireAuth: false,
      keepAlive: false,
    },
  },
  {
    path: "/tableTool", //url后面路径
    name: "tableTool",
    component: tableTool,
    meta: {
      requireAuth: false,
      keepAlive: false,
    },
  },
  {
    path: "/testDemo", //url后面路径
    name: "testDemo",
    component: testDemo,
    meta: {
      requireAuth: false,
      keepAlive: false,
    },
  },
  {
    path: "/HelloWorld", //url后面路径
    name: "HelloWorld",
    component: HelloWorld,
    meta: {
      requireAuth: false,
      keepAlive: false,
    },
  },
  {
    path: "/home",
    component: rootView,
  },
  {
    path: "/rootView",
    name: "rootView",
    component: rootView,
    meta: {
      keepAlive: false,
      // requireAuth: true,
    },
  },
  {
    path: "/qrcode",
    name: "qrcode",
    component: qrcode,
    meta: {
      requireAuth: false,
      keepAlive: false,
    },
  },
  {
    path: "/addApiData",
    name: "addApiData",
    component: addApiData,
    meta: {
      requireAuth: false,
      keepAlive: false,
    },
  },
  {
    path: "/appInstall",
    name: "appInstall",
    component: appInstall,
    meta: {
      requireAuth: false,
      keepAlive: false,
    },
  },
];
