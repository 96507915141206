<template>
  <div class="contentViews">
    <div>
      <h1 class="contentText">调用方式</h1>
      <dev class="itemTitle">根据需求修改测试代码</dev>
      <!-- <van-cell-group inset>
        <van-field
          v-model="demoStr"
          rows="1"
          autosize
          type="textarea"
          placeholder="请输入接口调用代码"
          class="inputTextView"
          id="code-input"
        />
      </van-cell-group> -->
      <div class="input-with-line-numbers">
        <div class="line-numbers">
          <div
            v-for="(line, index) in codeLines"
            :key="index"
            class="line-number"
          >
            {{ index + 1 }}
          </div>
        </div>
        <textarea
          id="code-input"
          class="code-input"
          v-model="demoStr"
        ></textarea>
      </div>
    </div>
    <div class="testBtn" @click="testAction()">测试</div>
    <h1 class="itemTitle">结果</h1>
    <div class="resultText">{{ resultinfo }}</div>
    <!-- <textarea class="resultText" cols="30" rows="10" readonly>{{
      resultinfo
    }}</textarea> -->
    <div class="imgbackView">
      <img v-if="showImage" class="imageView" id="imgtest" alt="" />
    </div>
  </div>
</template>

<script>
import {
  apiData,
  funcationName,
  parameters,
  parametersRemark,
  callBack,
  demoStr,
} from "./apiData";

// import Vconsole from "vconsole";
export default {
  name: "HelloWorld",
  data() {
    return {
      index: 0,
      url_data: {},
      dataDic: {},
      resultinfo: null,
      imgStr: null,
      showImage: false,
      demoStr: "",
    };
  },
  created() {
    this.$nextTick(() => {
      // 在此处执行你要执行的函数
      this.getURLData();
      document.title = this.dataDic.title;
      this.demoStr = this.dataDic.demo.replaceAll("<br/>", "\n");
      console.log(this.demoStr);
      document.getElementById("code-input").value = this.demoStr;
      // console.log(this.dataDic.demo);
    });
    // var vConsole = new VConsole();
  },
  computed: {
    codeLines() {
      console.log(this.demoStr);
      return this.demoStr.split("\n");
    },
  },
  methods: {
    // codeLines() {
    //   return this.demoStr.split("\n");
    // },
    getURLData() {
      var url = window.location.href; //获取当前url
      console.log(url);
      //   var dz_url = url.split("#")[0]; //获取#/之前的字符串
      var cs = url.split("?")[1]; //获取?之后的参数字符串
      //   console.log(cs);
      var cs_arr = cs.split("&"); //参数字符串分割为数组
      //   console.log(cs_arr);

      for (var i = 0; i < cs_arr.length; i++) {
        //遍历数组，拿到json对象
        this.url_data[cs_arr[i].split("=")[0]] = cs_arr[i].split("=")[1];
      }
      console.log(this.url_data.index);
      this.index = this.url_data.index;
      this.dataDic = apiData[this.url_data.index];
      console.log(this.dataDic);
      if (this.url_data.index >= 18 && this.url_data.index <= 20) {
        this.showImage = true;
      }

      //   this.id = cs.id; //这样就拿到了参数中的数据
    },
    funcationName() {
      return funcationName(this.index);
    },
    testAction() {
      //   this.resultinfo = "this.index";
      this.resultinfo = "";
      // console.log("testAction" + this.index);
      // console.log(this.demoStr);
      var mtr = document.getElementById("code-input");
      if (mtr) {
        this.demoStr = mtr.value;
      }
      console.log("this.demoStr");
      console.log(this.demoStr);
      // eval(this.demoStr);
      try {
        eval(this.demoStr);
      } catch (e) {
        this.resultinfo = e;
        console.error("执行失败:", e);
      }
      // switch (parseInt(this.index)) {
      //   case 0: //原生返回不带参数
      //     bxt.backHome();
      //     break;
      //   case 1: //原生返回原生指定页
      //     var parameters = { popToIndex: "-1" };
      //     bxt.backHome(parameters);
      //     break;
      //   case 2: //清除本地缓存
      //     bxt.deleteWebCache();
      //     break;
      //   case 3: //开启扫码页
      //     var param = { type: "0" };
      //     bxt.scanCode(param, (info) => {
      //       console.log("block data");
      //       this.resultinfo = info;
      //       console.log(this.resultinfo);
      //     });
      //     break;
      //   case 4: //添加扫码框
      //     var parameters = {
      //       frameDic: { x: 100, y: 200, w: 200, h: 200 },
      //       scanReact: { x: 0, y: 0, w: 200, h: 200 },
      //       scanType: 0,
      //       delay: 1,
      //       repeats: 0,
      //     };
      //     bxt.initScanView(parameters, (info) => {
      //       this.resultinfo = info;
      //       console.log(this.resultinfo);
      //     });
      //     break;
      //   case 5: //隐藏扫码框
      //     bxt.hideScanView();
      //     break;
      //   case 6: //移除扫码框
      //     bxt.moveScanView();
      //     break;
      //   case 7: //显示扫码框
      //     bxt.showScanView();
      //     break;
      //   case 8: //获取用户信息
      //     // eval(this.demoStr);
      //     var param = { keys: ["login_id"] };
      //     bxt.getUserInfo(param, (info) => {
      //       console.log("block data");
      //       this.resultinfo = info;
      //       console.log(this.resultinfo);
      //     });
      //     break;
      //   case 9: //跳转原生页面
      //     var parameters = {
      //       pagename: "FindStoreViewController",
      //       data: { justSelecte: true },
      //     };
      //     bxt.pushViewController(parameters, (info) => {
      //       this.resultinfo = info;
      //     });
      //     break;
      //   case 10: //打开文档
      //     var parameters = {
      //       path: "http://kmcs.lbxcn.com:8083/sys/attachment/sys_att_main/sysAttMain.do?method=download&fdId=17b534479763564acf482c84fcba269e",
      //       title: "title",
      //     };
      //     bxt.openFile(parameters);
      //     break;
      //   case 11: //打开文档带底部按钮
      //     var bottomDic = { title: "click" };
      //     var parameters = {
      //       path: "http://kmcs.lbxcn.com:8083/sys/attachment/sys_att_main/sysAttMain.do?method=download&fdId=17b534479763564acf482c84fcba269e",
      //       bottomDic: bottomDic,
      //       title: "title",
      //     };
      //     bxt.openFileWitchbottomDic(parameters, (info) => {
      //       this.resultinfo = "click";
      //     });
      //     break;
      //   case 12: //添加播放器
      //     var parameters = {
      //       frame: ["0", "0", "600", "300"],
      //       tag: "tag",
      //       url: "http://1255573980.vod2.myqcloud.com/3978b90fvodcq1255573980/064e0ec18602268011128428854/aYe28UIGQMIA.mp4",
      //       time: "1",
      //       slide: "1",
      //       isStop: "1",
      //       isplayer: "1",
      //       canSetSpeet: "1",
      //       change: "0",
      //     };
      //     console.log(parameters);
      //     bxt.addPlayerView(parameters);
      //     break;
      //   case 13: //位置信息
      //     bxt.getUserLocation((info) => {
      //       this.resultinfo = info;
      //     });
      //     break;
      //   case 14: //获取鉴权信息
      //     bxt.getToken((info) => {
      //       this.resultinfo = info;
      //     });

      //     break;
      //   case 15: //HUD提示
      //     var parameters = { message: "msg", type: "1" };
      //     bxt.showHud(parameters);
      //     break;
      //   case 16: //加载完成页面回调
      //     bxt.webLoadFinished();
      //     break;
      //   case 17: //修改原生头
      //     var parameters = {
      //       ishide: "0",
      //       tabbarColor: "#303133",
      //       title: "title",
      //     };
      //     bxt.setNavibar(parameters);
      //     break;
      //   case 18: //打开相机
      //     var parameters = {};
      //     bxt.openCamera(parameters, (info) => {
      //       var obj = JSON.parse(info.data);
      //       document.getElementById("imgtest").setAttribute("src", obj.image);
      //     });
      //     break;
      //   case 19: //打开相册
      //     var parameters = {};
      //     bxt.openPhoto(parameters, (info) => {
      //       var obj = JSON.parse(info.data);
      //       document.getElementById("imgtest").setAttribute("src", obj.image);
      //     });
      //     break;
      //   case 20: //获取截屏图片
      //     bxt.takeScreenshot((info) => {
      //       var obj = JSON.parse(info.data);
      //       document.getElementById("imgtest").setAttribute("src", obj.image);
      //     });
      //     break;
      //   case 21: //保存图片到相册
      //     var parameters = { image: "base64imgStr" };
      //     bxt.saveImage(parameters);
      //     break;
      //   case 22: //修改底部收拾栏
      //     var parameters = {
      //       hidden: "0",
      //       backgroundColor: "#909399",
      //       alpha: "1",
      //     };
      //     bxt.setBottomBar(parameters);
      //     break;

      //   default:
      //     var param = { keys: ["login_id"] };
      //     bxt.getUserInfo(param, (info) => {
      //       console.log("block data");
      //       this.resultinfo = info;
      //       console.log(this.resultinfo);
      //     });
      //     break;
      // }

      //   bxt.backHome();

      //   var param = { keys: ["login_id"] };
      //   bxt.getUserInfo(param, function(info) {
      //     console.log(info);
      //     this.resultinfo = info["login_id"];
      //     console.log(this.resultinfo);
      //   });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  margin: 0;
  padding: 0;
}
.contentViews {
  /* width: 100%; */
  margin: 0px;
  padding: 15px;
  /* background-color: rgb(194, 194, 188); */
  /* width: 100%;
  height: 100px;
  line-height: 100px; */
  /* text-align: center; */
  display: flex;
  flex-direction: column;

  overflow: hidden;
}
.titleView {
  /* width: 100%; */
  line-height: 50px;
  height: 50px;
  /* border: 2px solid #3852c9;
  background-color: rgb(92, 126, 189); */
  color: rgba(11, 12, 12, 1);
  font-size: 24px;
  border-radius: 5px;
}
.inputTextView {
  height: 300px;
  width: 100%;
  color: red;
  background-color: black;
}
/* ::v-deep .van-field__control::-webkit-input-placeholder {
  color: #9b9ba4;
} */

::v-deep #myTextarea {
  color: white;
}
.contentText {
  text-align: left;
  font-size: 24px;
}
.testBtn {
  margin-top: 50px;

  line-height: 50px;
  height: 50px;
  /* width: 100%; */
  border: 2px solid #3852c9;
  background-color: rgb(92, 126, 189);
  color: rgba(11, 12, 12, 1);
  font-size: 24px;
  border-radius: 5px;
}
.itemTitle {
  width: 100%;
  text-align: left;
}
.resultText {
  margin-top: 20px;
  width: 100%;
  /* background-color: rgb(201, 207, 218); */
  text-align: left;
  border: none;
  word-break: break-all;
  white-space: pre-wrap;
}
.imageView {
  /* background-color: rgb(122, 144, 184); */
  width: 300px;
  height: 300px;
}

.input-with-line-numbers {
  display: flex;
  height: 300px;
}

.line-numbers {
  counter-reset: line;
  padding: 10px 10px;
  border-right: 1px solid #ccc;
  color: white;
  background-color: black;
}

.line-number {
  counter-increment: line;
  line-height: 1.5em;
  text-align: right;
}

.code-input {
  flex: 1;
  border: none;
  outline: none;
  font-family: monospace;
  padding: 10px;
  line-height: 1.5em;
  color: white;
  background-color: black;
}
</style>
