import axios from "axios";
// 进行一些全局配置
// axios.defaults.baseURL = "http://127.0.0.1/api";
// axios.defaults.baseURL = "http://localhost:8080/api";
// axios.defaults.baseURL = "http://150.158.176.173:9999/";
// axios.defaults.baseURL = "http://10.0.220.71:9999";
// axios.defaults.baseURL = "/api";
// axios.defaults.baseURL = "";
//   process.env.NODE_ENV === "development" ? "" : "http://150.158.176.173:9999";
axios.defaults.timeout = 6000000;
let count = 0;
// 添加请求拦截器
axios.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    count++;
    // Vue.showLoading();
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    // Vue.hiddenLoading();
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    count--;
    if (count === 0) {
      //   Vue.hiddenLoading();
    }
    return response;
  },
  function (error) {
    // 对响应错误做点什么
    // Vue.hiddenLoading();
    return Promise.reject(error);
  }
);
// const express = require("express");
// const app = express();

// // 获取客户端 IP 地址的路由
// app.get("/api/getClientIP", (req, res) => {
//   // 获取客户端 IP 地址
//   const clientIP = req.ip;
//   res.json({ clientIP });
// });
// 封装自己的get/post方法
export default {
  get: function (path = "", data = {}) {
    var pathStr = path;
    console.log("========" + path);
    return new Promise(function (resolve, reject) {
      axios
        .get(pathStr, {
          params: data,
          dataType: "JSON",
        })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  post: function (path = "", data = {}) {
    return new Promise(function (resolve, reject) {
      axios
        .post(path, data)
        .then(function (response) {
          console.log(response.data.status);
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  all: function (list) {
    return new Promise(function (resolve, reject) {
      axios
        .all(list)
        .then(
          axios.spread(function (...result) {
            // 两个请求现在都执行完成
            resolve(result);
          })
        )
        .catch(function (err) {
          reject(err);
        });
    });
  },
  // upload: function (path = "", data = {}) {
  upload: function (path = "", data = {}, index, callback) {
    return new Promise(function (resolve, reject) {
      // axios
      //   .post(path, data, callback)
      //   .then(function (response) {
      //     console.log(response.data.status);
      //     resolve(response.data);
      //   })
      //   .catch(function (error) {
      //     reject(error);
      //   });

      var qrStr;
      if (process.env.NODE_ENV === "development") {
        qrStr = "http://10.0.220.71:9999" + path;
        // alert("开发环境");
      } else {
        // alert("生产环境");
        qrStr = "https://www.thtlove.top" + path;
      }

      // console.log(data);
      // console.log("data");

      axios({
        url: qrStr,
        method: "post",
        data: data,
        onUploadProgress: function (progressEvent) {
          console.log("progressEvent");
          callback({
            state: "1",
            progress: progressEvent.progress,
            index: index,
          });
        },
      })
        .then((res) => {
          console.log("res.data");
          resolve(res.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
};

export function httpRequest(paramObj, fun, errFun) {
  var xmlhttp = null;
  /* 创建XMLHttpRequest对象，
   *老版本的 Internet Explorer（IE5 和 IE6）使用 ActiveX 对象：new ActiveXObject("Microsoft.XMLHTTP")
   * */
  if (window.XMLHttpRequest) {
    xmlhttp = new XMLHttpRequest();
  } else if (window.ActiveXObject) {
    xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
  }
  /* 判断是否支持请求*/
  if (xmlhttp == null) {
    alert("你的浏览器不支持XMLHttp");
    return;
  }
  /* 请求方式，并且转换为大写*/
  var httpType = (paramObj.type || "GET").toUpperCase();
  /* 数据类型*/
  // var dataType = paramObj.dataType || 'json'
  /* 请求接口*/
  var httpUrl = paramObj.httpUrl || "";
  /* 是否异步请求*/
  var async = paramObj.async || true;
  /* 请求参数--post请求参数格式为：foo=bar&lorem=ipsum*/
  var paramData = paramObj.data || [];
  var requestData = "";
  for (var name in paramData) {
    requestData += name + "=" + paramData[name] + "&";
  }
  requestData =
    requestData === "" ? "" : requestData.substring(0, requestData.length - 1);
  console.log(requestData);

  /* 请求接收*/
  xmlhttp.onreadystatechange = function () {
    if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
      /* 成功回调函数*/
      fun(xmlhttp.responseText);
    } else {
      /* 失败回调函数*/
      errFun;
    }
  };

  /* 接口连接，先判断连接类型是post还是get*/
  if (httpType === "GET") {
    xmlhttp.open("GET", httpUrl, async);
    xmlhttp.send(null);
  } else if (httpType === "POST") {
    xmlhttp.open("POST", httpUrl, async);
    // 发送合适的请求头信息
    xmlhttp.setRequestHeader(
      "Content-type",
      "application/x-www-form-urlencoded"
    );
    xmlhttp.send(requestData);
  }
}
