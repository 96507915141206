<template>
  <div
    class="input-with-line-numbers"
    :style="{
      backgroundColor: backColor,
    }"
  >
    <div class="line-numbers" id="line-numbers">
      <div
        v-for="(line, index) in lines"
        :key="index"
        class="line-numberContent"
      >
        {{ index + 1 }}
      </div>
    </div>
    <textarea
      id="code-input"
      class="code-input"
      v-model="input_str"
      @input="updateInput"
      @scroll="scrollAction"
      :style="{
        backgroundColor: bgColor,
      }"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: "codeInput",
  props: {
    // 接收参数
    str: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    bgColor: {
      type: String,
      default: "black",
    },
  },
  data() {
    return {
      lines: 1,
      input_str: "his.value",
      index: 0,
      backColor: "black",
    };
  },

  created() {
    this.input_str = this.value;
    this.backColor = this.bgColor;
    console.log(this.bgColor);
  },
  watch: {},
  methods: {
    updateInput(event) {
      const inputStr = event.target.value;
      let ls = inputStr.split("\n");
      this.lines = ls > 0 ? ls : 1;
      // 当 input 值变化时，通知父组件
      this.$emit("input-change", inputStr);
      //   console.log(inputStr);
    },
    scrollAction() {
      console.log("scroll");
      var textarea = document.getElementById("code-input");
      var syncedDiv = document.getElementById("line-numbers");

      // 计算滚动比例
      var scrollTopPercentage =
        textarea.scrollTop / (textarea.scrollHeight - textarea.clientHeight);

      // 应用相同的滚动比例到 div 元素
      syncedDiv.scrollTop =
        scrollTopPercentage * (syncedDiv.scrollHeight - syncedDiv.clientHeight);
    },
  },
};
</script>

<style scoped>
.input-with-line-numbers {
  display: flex;
  /* height: 300px; */
}

.line-numbers {
  counter-reset: line;
  padding: 10px 10px;
  border-right: 1px solid #ccc;
  color: white;
  /* background-color: black; */
  overflow-y: auto;
  /* display: none; */
  overflow: hidden;
}

.line-number {
  counter-increment: line;
  line-height: 1.3em;
  text-align: right;
}

.code-input {
  flex: 1;
  border: none;
  outline: none;
  font-family: monospace;
  padding: 10px;
  line-height: 1.5em;
  color: rgb(226, 224, 224);
  /* background-color: black; */
  resize: none; /* 禁止拖动放大 */
}
</style>
