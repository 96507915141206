<template>
  <div class="backView">
    <canvas id="myCanvas" width="500" height="300"></canvas>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.xianDuan();
  },
  methods: {
    xianDuan() {
      var canvas = document.getElementById("myCanvas");
      var ctx = canvas.getContext("2d");
      ctx.moveTo(0, 0); // moveTo(x,y) 定义线条开始坐标
      ctx.lineTo(200, 100); // lineTo(x,y) 定义线条结束坐标
      ctx.stroke();
    },
    sanJiaoXing() {
      var canvas = document.getElementById("myCanvas");
      var ctx = canvas.getContext("2d");
      ctx.beginPath();
      ctx.moveTo(0, 0);
      ctx.lineTo(100, 200);
      ctx.lineTo(400, 200);
      ctx.lineTo(0, 0);
      ctx.stroke(); // 无填充色
    },
  },
};
</script>

<style>
.backView {
  width: 100%;
  height: 1000px;
  background-color: orange;
}
</style>
